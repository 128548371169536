import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useLogin } from '../../hooks/useLogin'

export default function AdminLogin() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, error, isPending } = useLogin()
  const role = 'admin'

  const handleSubmit = (e) => {
    e.preventDefault()
    alert(role)
    login(email, password, role)
  }

  return (
    <>
      <Form.Group className='auth-form'>
        <h2>Admin Log in</h2>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type='email'
          required
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <Form.Label>Password</Form.Label>
        <Form.Control
          type='password'
          required
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <Form.Text
          className='text-muted'
          style={{
            cursor: 'pointer',
            color: 'blue',
            textDecoration: 'underline',
            fontWeight: 'bold',
          }}
          onClick={() => alert('Handle Forgot Password')}
        >
          {' '}
          Forgot password?
        </Form.Text>
        <br />
        <br />
        {!isPending && (
          <Button className='btn' onClick={handleSubmit}>
            Log in
          </Button>
        )}
        {isPending && (
          <Button className='btn' disabled>
            loading
          </Button>
        )}
        {error && <div className='error'>{error}</div>}
      </Form.Group>
    </>
  )
}
