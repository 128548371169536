import React, { useRef, useState, useEffect } from 'react'
import { Container, Form } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
// import 'react-bootstrap-typeahead/css/Typeahead.css'
import { useAdmin } from '../contexts/AdminContext'

const SearchBox = () => {
  const [selectedCity, setSelectedCity] = useState()

  const {
    services,
    search,
    setSearch,
    selectedServ,
    setSelectedServ,
    citiesOffered,
  } = useAdmin()
  const ref = useRef(null)
  useEffect(() => {
    const names = []
    services &&
      services.forEach((service) => {
        service.enabled && names.push(service.name)
      })
    setSearch(names)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services])

  return (
    <div
      className='site-banner'
      style={{
        backgroundImage: 'url(/images/uploaded/main-bg.jpg)',
        marginTop: '50px',
      }}
    >
      <Container>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className='site-banner__content'>
          <h1 className='site-banner__title'>Home Services, On Demand.</h1>
          <Form.Group className='site-banner__search layout-02 '>
            <label className='label-omni' htmlFor='service'>
              Find
            </label>
            {/* <Form.Control
              id='service'
              as='select'
              className='site-banner__search__input'
              size='lg'
              custom
            >
              {services.map((service) => (
                <option key={service.id}>{service.name}</option>
              ))}
            </Form.Control> */}
            <div className='site-banner__search__input'>
              <Typeahead
                style={{
                  all: 'unset',
                }}
                options={search && search}
                placeholder='Search your service'
                ref={ref}
                id='basic-typeahead-single'
                value={selectedServ}
                onInputChange={(e) => {
                  setSelectedServ(e)
                }}
                onChange={(e) => {
                  setSelectedServ(e[0])
                }}
              />
            </div>
            <label className='label-omni' htmlFor='location'>
              Where
            </label>

            <div className='site-banner__search__input'>
              <Typeahead
                style={{
                  all: 'unset',
                }}
                options={citiesOffered}
                placeholder='Search your City'
                ref={ref}
                id='basic-typeahead-single'
                value={selectedCity}
                onInputChange={(e) => {
                  setSelectedCity(e)
                }}
                onChange={(e) => {
                  setSelectedCity(e[0])
                }}
              />
            </div>
            {/* 
            <div className='field-submit'>
              <button>
                <i className='las la-search la-24-black'></i>
              </button>
            </div> */}
          </Form.Group>
          <p
            className='site-banner__meta'
            style={{ color: 'white', fontFamily: 'Josh', fontWeight: 'bold' }}
          >
            <span>Hint:</span>
            Please start typing to search for services
          </p>
        </div>
      </Container>
    </div>
  )
}

export default SearchBox
