import { useState } from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { useUsers } from '../../contexts/UsersContext'

const Announcement = () => {
  const [showModal, setShowModal] = useState(true)
  const { loggedOnUser } = useUsers()

  return (
    <Container>
      <Row className='member-wrap-top'>
        <Col sm={6}>
          <h2>Welcome back, {loggedOnUser && loggedOnUser.name}!</h2>
        </Col>
        <Col sm={6} align='right'>
          <p>
            {loggedOnUser && loggedOnUser.verificationStatus
              ? `Account Status: ${loggedOnUser.verificationStatus}`
              : ``}
          </p>
        </Col>
      </Row>

      {showModal && (
        <div className='upgrade-box'>
          <h1>Announcement</h1>
          <p>
            Some Services Might Require Verification Before They Can be Used.
            <br />
            {loggedOnUser &&
              (!loggedOnUser.company || !loggedOnUser.company.address) &&
              'Your business details are incomplete. Please update them.'}
            <br />
            {loggedOnUser &&
              (!loggedOnUser.phone || !loggedOnUser.address) &&
              'Your account details are incomplete. Please update them.'}
          </p>
          <Button
            onClick={() => setShowModal(false)}
            className='btn upgrade-btn'
          >
            Close
          </Button>
          <Image src='/images/uploaded/img-people.png' alt='Announcement' />
        </div>
      )}
    </Container>
  )
}

export default Announcement
