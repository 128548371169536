import { useState } from 'react'
import { Navbar, Nav, Row, Col, Dropdown } from 'react-bootstrap'
import '@fontsource/jost/500.css'
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
import { isMobile } from 'react-device-detect'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext'
import { useLogout } from '../hooks/useLogout'
import '../../src/resources/css/style.css'

// import { Link } from 'react-router-dom'

const Header = () => {
  const [expanded, setExpanded] = useState(false)
  const { user } = useAuthContext()
  const { logout } = useLogout()

  const navigate = useNavigate()
  const location = useLocation()
  const [showForm, setShowForm] = useState(
    (location && location.state && location.state.showForm) || false
  )
  const [role, setRole] = useState(
    (location && location.state && location.state.role) || 'none'
  )

  return (
    <div className=''>
      <div
        className='site-banner'
        style={{
          height: '50px',
        }}
      >
        <Row style={{ width: '100%' }}>
          <Navbar
            expanded={expanded}
            expand='sm'
            style={{ backgroundColor: '#ffffff' }}
            variant='light'
            className={
              isMobile
                ? 'fixed-top-omni-mobile'
                : 'justify-content-center fixed-top-omni'
            }
            // className='fixed-top-omni'
          >
            <Navbar.Toggle
              className='site__menu'
              onClick={() => setExpanded(expanded ? false : true)}
              aria-controls='responsive-navbar-nav'
              // style={{ color: '#ffffff' }}
            >
              <i
                className={
                  expanded ? 'las la-chevron-up' : 'las la-chevron-down'
                }
              ></i>
            </Navbar.Toggle>{' '}
            {'  '}
            <Col sm={3} style={{ paddingLeft: '20px', alignSelf: 'center' }}>
              <Navbar.Brand>
                <div className={!isMobile && 'site__brand site__brand__logo'}>
                  <img src='/images/assets/logo.png' alt='Omni Seva' />
                </div>
              </Navbar.Brand>
            </Col>
            <Navbar.Collapse id='responsive-navbar-nav' variant='light'>
              <Col sm={9}>
                <Nav className='main-menu-omni' style={{ fontWeight: '700' }}>
                  <Nav.Link
                    style={{ paddingRight: '30px' }}
                    className={!expanded ? 'padding-omni' : ''}
                    onClick={() => {
                      navigate('/')
                      setExpanded(false)
                    }}
                  >
                    Home
                  </Nav.Link>
                  {/* <Nav.Link
                  style={{ paddingRight: '30px' }}
                  onClick={() => {
                    alert('About us')
                    setExpanded(false)
                  }}
                >
                  About Us
                </Nav.Link>
                <Nav.Link
                  style={{ paddingRight: '30px' }}
                  onClick={() => {
                    alert('Services')
                    setExpanded(false)
                  }}
                >
                  Our Services
                </Nav.Link>
               */}
                  <Nav.Link
                    style={{ paddingRight: '30px' }}
                    onClick={() => {
                      alert('Contact Us')
                      setExpanded(false)
                    }}
                  >
                    Contact Us
                  </Nav.Link>
                  {/* <Nav.Link
                  style={{ paddingRight: '30px' }}
                  onClick={() => {
                    // alert('Supplier Registration')
                    navigate('/SupplierProfile')
                    setExpanded(false)
                  }}
                >
                  Join as Professional
                </Nav.Link> */}
                  <Nav.Link
                    style={{ paddingRight: '30px' }}
                    as={Link}
                    to='/business'
                  >
                    Business Dashboard
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => {
                      navigate('/person', {
                        replace: true,
                        state: { role: 'Personal', showForm: true },
                      })
                    }}
                  >
                    My Dashboard
                  </Nav.Link>
                </Nav>{' '}
              </Col>{' '}
              {!user ? (
                <Col sm={2}>
                  <Dropdown>
                    <Dropdown.Toggle variant='success' id='dropdown-basic'>
                      Login
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        background: 'red',
                        fontweight: 'bold',
                        cursor: 'pointer',
                      }}
                    >
                      <Dropdown.Item className='logindropdownitem'>
                        <button
                          style={{
                            background: 'transparent',
                            color: 'black',
                            border: 'none',
                          }}
                          onClick={() => {
                            navigate('/login', {
                              replace: true,
                              state: { role: 'Personal', showForm: true },
                            })
                          }}
                        >
                          Personal Login{' '}
                        </button>
                      </Dropdown.Item>
                      <Dropdown.Item className='logindropdownitem'>
                        <button
                          style={{
                            background: 'transparent',
                            color: 'black',
                            border: 'none',
                          }}
                          onClick={() => {
                            navigate('/login', {
                              replace: true,
                              state: { role: 'Business', showForm: true },
                            })
                          }}
                        >
                          {' '}
                          Business Login{' '}
                        </button>
                      </Dropdown.Item>
                      <Dropdown.Item className='logindropdownitem'>
                        <button
                          style={{
                            background: 'transparent',
                            color: 'black',
                            border: 'none',
                          }}
                          onClick={() => {
                            console.log(role)
                            setRole('none')
                            setShowForm(false)
                            navigate('/register')
                          }}
                        >
                          Register Here
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              ) : (
                <Col
                  sm={2}
                  className='right-header__login right-header__button btn'
                  style={{ paddingLeft: '0px' }}
                >
                  <Link className='open-login' onClick={() => logout()}>
                    Logout
                  </Link>
                </Col>
              )}
            </Navbar.Collapse>
          </Navbar>
          <hr style={{ height: '43.8pt', visibility: 'hidden' }} />
        </Row>
      </div>

      {/* {showForm && (
        <Form.Group className='auth-form'>
          <div
            style={{ alignContent: 'center' }}
            className='site__brand site__brand__logo'
          >
            <img src='/images/assets/logo.png' alt='Omni Seva' />
          </div>
          <h2 style={{ textAlign: 'center' }}>{role} Log in</h2>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type='email'
            required
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            required
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <Form.Text
            className='text-muted'
            style={{
              cursor: 'pointer',
              color: 'blue',
              textDecoration: 'underline',
              fontWeight: 'bold',
            }}
            onClick={() => alert('Handle Forgot Password')}
          >
            {' '}
            Forgot password?
          </Form.Text>
          <br />
          <br />
          {!isPending && (
            <Button className='btn' onClick={handleSubmit}>
              Log in
            </Button>
          )}
          {isPending && (
            <Button className='btn' disabled>
              loading
            </Button>
          )}
          {error && <div className='error'>{error}</div>}
        </Form.Group>
      )} */}
    </div>
  )
}

export default Header
