import { useState, useEffect } from 'react'
import './radio.css'

import { collection, query, addDoc, onSnapshot } from 'firebase/firestore'
import { db } from '../../config/Firebase'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useUsers } from '../../contexts/UsersContext'

const RadioChecks = () => {
  const [radios, setRadios] = useState('')
  const [checks, setChecks] = useState('')
  const [error, setError] = useState(null)
  const [newRadio, setNewRadio] = useState({ name: '', options: [] })
  const [newCheck, setNewCheck] = useState({ name: '', options: [] })
  const [showRadioForm, setShowRadioForm] = useState(false)
  const [showCheckForm, setShowCheckForm] = useState(false)
  const [option, setOption] = useState('')
  const [radioName, setRadioName] = useState('')
  const [checkName, setCheckName] = useState('')
  const [radioOptions, setRadioOptions] = useState([])
  const [checkOptions, setCheckOptions] = useState([])
  const { loggedOnUser } = useUsers()

  useEffect(() => {
    getRadios()
    getChecks()
  }, [])

  const getRadios = async () => {
    const q = query(collection(db, 'radioButtons'))
    await onSnapshot(
      q,
      (querySnapshot) => {
        let rcvdRadios = []
        querySnapshot.forEach((doc) => {
          rcvdRadios.push({ id: doc.id, ...doc.data() })
        })
        setRadios(rcvdRadios)
      },
      (err) => {
        console.log('ERROR SNAP: ', err)
        setError('Error getting Radios')
      }
    )
  }

  const getChecks = async () => {
    const q = query(collection(db, 'checkBoxes'))
    await onSnapshot(
      q,
      (querySnapshot) => {
        let rcvdChecks = []
        querySnapshot.forEach((doc) => {
          rcvdChecks.push({ id: doc.id, ...doc.data() })
        })
        setChecks(rcvdChecks)
      },
      (err) => {
        console.log('ERROR SNAP: ', err)
        setError('Error getting Checks')
      }
    )
  }
  const addRadioOptionsHandler = () => {
    radioOptions.push(option)
    setNewRadio({ name: radioName, options: radioOptions })
    setOption('')
  }

  const saveRadioOptionsHandler = async () => {
    await addDoc(collection(db, 'radioButtons'), {
      ...newRadio,
      createdOn: new Date().toLocaleString() + '',
      createdBy: loggedOnUser && loggedOnUser.name,
    })
    console.log('Radio to save: ', newRadio)
    setRadioName('')
    setRadioOptions([])
    setShowRadioForm(false)
  }

  const addCheckOptionsHandler = () => {
    checkOptions.push(option)
    setNewCheck({ name: checkName, options: checkOptions })
    setOption('')
  }

  const saveCheckOptionsHandler = async () => {
    await addDoc(collection(db, 'checkBoxes'), {
      ...newCheck,
      createdOn: new Date().toLocaleString() + '',
      createdBy: loggedOnUser && loggedOnUser.name,
    })
    console.log('Check to save: ', newCheck)
    setCheckName('')
    setCheckOptions([])
    setShowCheckForm(false)
  }

  return (
    <>
      <br />
      Radios: <Button onClick={() => setShowRadioForm(true)}>Add New</Button>
      {error && <div>{error}</div>}
      <Container>
        {radios &&
          radios.map((radio) => (
            <Row key={radio.id}>
              <Col sm={2}>{radio.name}:</Col>
              <Col sm={8}>
                {radio.options.map((option, index) => (
                  <span key={index} style={{ padding: '20px' }}>
                    {option}
                  </span>
                ))}
              </Col>
              <Col sm={2}>
                <Button onClick={() => alert('Create Form to edit this')}>
                  Modify
                </Button>
              </Col>
            </Row>
          ))}
      </Container>
      <br />
      <br />
      Checks: <Button onClick={() => setShowCheckForm(true)}>Add New</Button>
      {error && <div>{error}</div>}
      <Container>
        {checks &&
          checks.map((check) => (
            <Row key={check.id}>
              <Col sm={1}>{check.name}:</Col>
              <Col sm={9}>
                {check.options.map((option, index) => (
                  <span key={index} style={{ padding: '20px' }}>
                    {option}
                  </span>
                ))}
              </Col>{' '}
              <Col sm={2}>
                <Button onClick={() => alert('Create Form to edit this')}>
                  Modify
                </Button>
              </Col>
            </Row>
          ))}
      </Container>
      {showRadioForm && (
        <div>
          Radio Form
          <Form.Group className='mb-3'>
            <Form.Label>Radio Name</Form.Label>
            <Form.Control
              type='text'
              value={radioName}
              placeholder='Enter Radio Name'
              onChange={(e) => setRadioName(e.target.value)}
            />
            <Form.Control
              value={option}
              required
              style={{
                borderColor: 'blue',
              }}
              onChange={(e) => setOption(e.target.value)}
              placeholder='Add Option'
            />

            <Button
              disabled={false}
              onClick={() => {
                addRadioOptionsHandler()
              }}
            >
              Add
            </Button>
            {radioOptions &&
              radioOptions.map((opt, index) => (
                <div key={index}>
                  <span>{opt}</span>
                  <Button onClick={() => alert(index)}>Remove</Button>
                </div>
              ))}
          </Form.Group>
          <Button
            disabled={false}
            onClick={() => {
              setRadioName('')
              setRadioOptions([])
              setShowRadioForm(false)
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={false}
            onClick={() => {
              saveRadioOptionsHandler()
            }}
          >
            Save
          </Button>
        </div>
      )}
      {showCheckForm && (
        <div>
          Check Form
          <Form.Group className='mb-3'>
            <Form.Label>Check Name</Form.Label>
            <Form.Control
              type='text'
              value={checkName}
              placeholder='Enter Check Name'
              onChange={(e) => setCheckName(e.target.value)}
            />
            <Form.Control
              value={option}
              required
              style={{
                borderColor: 'blue',
              }}
              onChange={(e) => setOption(e.target.value)}
              placeholder='Add Option'
            />

            <Button
              disabled={false}
              onClick={() => {
                addCheckOptionsHandler()
              }}
            >
              Add
            </Button>
            {checkOptions &&
              checkOptions.map((opt, index) => (
                <div key={index}>
                  <span>{opt}</span>
                  <Button onClick={() => alert(index)}>Remove</Button>
                </div>
              ))}
          </Form.Group>
          <Button
            disabled={false}
            onClick={() => {
              setCheckName('')
              setCheckOptions([])
              setShowCheckForm(false)
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={false}
            onClick={() => {
              saveCheckOptionsHandler()
            }}
          >
            Save
          </Button>
        </div>
      )}
    </>
  )
}

export default RadioChecks
