import React, { useState, useEffect } from 'react'
import { Container, Spinner } from 'react-bootstrap'

const BusinessRoleError = () => {
  const [imgLoaded, setImgLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setImgLoaded(true)
    }, 1000)
  }, [])
  return (
    <div
      style={{
        backgroundColor: '#ededff',
      }}
    >
      <Container>
        {!imgLoaded && (
          <Spinner
            animation='border'
            variant='primary'
            size='500px'
            style={{ marginTop: '200px', width: '5rem', height: '5rem' }}
          />
        )}
        {imgLoaded && <h2>This section is for Business Users Only</h2>}
      </Container>
    </div>
  )
}

export default BusinessRoleError
