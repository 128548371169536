const Footer = () => {
  return (
    <>
      <footer
        id='footer'
        className='footer'
        style={{
          position: 'relative',
          bottom: 0,
        }}
      >
        <div className='container'>
          <div className='footer__top'>
            <div className='row'>
              <div className='col-lg-5'>
                <div className='footer__top__info'>
                  <a
                    title='Logo'
                    href='index.html'
                    className='footer__top__info__logo'
                  >
                    <img src='assets/images/assets/logo.png' alt='Omni Seva' />
                  </a>
                  {/* <p className='footer__top__info__desc'>
                    Discover amazing things to do everywhere you go.
                  </p> */}
                  {/* <div className='footer__top__info__app'>
                    <a
                      title='App Store'
                      href='/'
                      className='banner-apps__download__iphone'
                    >
                      <img
                        src='assets/images/assets/app-store.png'
                        alt='App Store'
                      />
                    </a>
                    <a
                      title='Google Play'
                      href='/'
                      className='banner-apps__download__android'
                    >
                      <img
                        src='assets/images/assets/google-play.png'
                        alt='Google Play'
                      />
                    </a>
                  </div> */}
                </div>
              </div>
              <div className='col-lg-2'>
                <aside className='footer__top__nav'>
                  <h3>Company</h3>
                  <ul>
                    <li>
                      <a title='About Us' href='/'>
                        About Omni Seva
                      </a>
                    </li>
                    <li>
                      <a title='Blog' href='/'>
                        Our Services
                      </a>
                    </li>
                    <li>
                      <a title='Faqs' href='/'>
                        Near Me
                      </a>
                    </li>
                    <li>
                      <a title='Contact' href='/'>
                        Contact
                      </a>
                    </li>
                  </ul>
                </aside>
              </div>
              <div className='col-lg-2'>
                <aside className='footer__top__nav'>
                  <h3>Support</h3>
                  <ul>
                    <li>
                      <a title='Get in Touch' href='/'>
                        Reviews
                      </a>
                    </li>
                    <li>
                      <a title='Help Center' href='/'>
                        Terms & Conditions
                      </a>
                    </li>
                    <li>
                      <a title='Live chat' href='/'>
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a title='How it works' href='/'>
                        How it works
                      </a>
                    </li>
                  </ul>
                </aside>
              </div>
              <div className='col-lg-3'>
                <aside className='footer__top__nav footer__top__nav--contact'>
                  <h3>Contact Us</h3>
                  <p>
                    Email:{' '}
                    <a
                      href='/'
                      className='__cf_email__'
                      data-cfemail='92e1e7e2e2fde0e6d2f6fdfff3fbfcbcf1fdff'
                    >
                      info@omniseva.com
                    </a>
                  </p>
                  <p>Phone: (+44) 7713 021 162</p>
                  <ul>
                    <li className='facebook'>
                      <a title='Facebook' href='/'>
                        <i className='la la-facebook-f'></i>
                      </a>
                    </li>
                    <li className='twitter'>
                      <a title='Twitter' href='/'>
                        <i className='la la-twitter'></i>
                      </a>
                    </li>
                    <li className='youtube'>
                      <a title='Youtube' href='/'>
                        <i className='la la-youtube'></i>
                      </a>
                    </li>
                    <li className='instagram'>
                      <a title='Instagram' href='/'>
                        <i className='la la-instagram'></i>
                      </a>
                    </li>
                    <li className='facebook'>
                      <a title='linkedin' href='/'>
                        <i className='la la-linkedin'></i>
                      </a>
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
          </div>
          <div className=''>
            <p className=''>
              2023 &copy;{' '}
              <a title='Omni Seva' href='/'>
                Omni Seva
              </a>
              . All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
