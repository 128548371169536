import { useState } from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { useUsers } from '../../contexts/UsersContext'

const Announcement = () => {
  const [showModal, setShowModal] = useState(true)
  const { loggedOnUser } = useUsers()

  return (
    <Container>
      <Row className='member-wrap-top'>
        <Col sm={6}>
          <h2>Welcome back, {loggedOnUser && loggedOnUser.name}! </h2>
        </Col>
        <Col sm={6} align='right'>
          <p>Account Status : {loggedOnUser && loggedOnUser.accountStatus}</p>
        </Col>
      </Row>

      {showModal && (
        <div className='upgrade-box' style={{ backgroundColor: 'lightgreen' }}>
          <h1>Place holder for messages</h1>
          <p>Please verify your email before you start using our services.</p>
          <Button
            onClick={() => setShowModal(false)}
            className='btn upgrade-btn'
          >
            Close
          </Button>
          <Image src='/images/uploaded/img-people.png' alt='Announcement' />
        </div>
      )}
    </Container>
  )
}

export default Announcement
