/**@global Admin Context */

/**
 * Get all Admin Data i.e. Users, Orders and any other collections - get all once and start SNAPSHOT listeners
 *
 *
 */
import React, { useContext, useEffect, useState } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'
import { db, auth } from '../config/Firebase'
import {
  collection,
  query,
  where,
  doc,
  updateDoc,
  onSnapshot,
} from 'firebase/firestore'

import { onAuthStateChanged } from 'firebase/auth'

const UsersContext = React.createContext()

export const useUsers = () => {
  return useContext(UsersContext)
}

const UsersProvider = ({ children }) => {
  const { user } = useAuthContext()
  const [loggedOnUser, setLoggedOnUser] = useState(null)
  const [error, setError] = useState(null)
  const [uid, setUid] = useState(null)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUid(user.uid)
      } else {
        setUid(null)
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (uid) {
      const q = user
        ? query(collection(db, 'users'), where('email', '==', user.email))
        : query(collection(db, 'users'))
      onSnapshot(
        q,
        (snapshot) => {
          let results = []
          snapshot.docs.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id })
          })
          setLoggedOnUser(results[0])
          setError(null)
        },
        (error) => {
          console.log(error)
          setError('could not fetch the data')
        }
      )
    }
  }, [uid])

  const updateLoginTime = async (id) => {
    try {
      const userRef = doc(db, 'users', id)
      await updateDoc(userRef, {
        lastSignInTime: new Date().toLocaleString() + '',
      })
    } catch (error) {
      console.log(error)
    }
  }

  const value = { error, loggedOnUser, updateLoginTime }
  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
}

export default UsersProvider
