import { useState, useRef } from 'react'
import {
  Table,
  Col,
  Button,
  Image,
  Container,
  Card,
  Form,
  ProgressBar,
  Accordion,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  ref,
} from 'firebase/storage'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from 'firebase/firestore'
import { Typeahead } from 'react-bootstrap-typeahead'

import { db, storage } from '../../config/Firebase'
import { useAdmin } from '../../contexts/AdminContext'
import { useUsers } from '../../contexts/UsersContext'

const Services = () => {
  const [servModal, setServModal] = useState()
  const [servToSave, setServToSave] = useState({})
  const [imgUrl, setImgUrl] = useState('Need to upload an image')
  const [progresspercent, setProgresspercent] = useState(0)
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState()
  const [hasRadioButtons, setHasRadioButtons] = useState(false)
  const [hasCheckBoxes, setHasCheckBoxes] = useState(false)

  const { categories, services, radioButtons, checkBoxes } = useAdmin()
  const { loggedOnUser } = useUsers()
  let selectedRadios = []
  let selectedCheckBoxes = []

  const ref1 = useRef()

  const manageSelectedRadios = (radioName) => {
    if (servToSave.radios && servToSave.radios.length > 0) {
      selectedRadios = servToSave.radios
      const dup = selectedRadios.indexOf(radioName)
      if (dup > 0) selectedRadios.splice(dup, 1)
      else selectedRadios.push(radioName)
    } else {
      selectedRadios.push(radioName)
    }
    servToSave.radios = selectedRadios
  }

  const manageSelectedCheckBoxes = (checkBoxName) => {
    if (servToSave.checkBoxes && servToSave.checkBoxes.length > 0) {
      selectedCheckBoxes = servToSave.checkBoxes
      const dup = selectedCheckBoxes.indexOf(checkBoxName)
      if (dup > 0) selectedCheckBoxes.splice(dup, 1)
      else selectedCheckBoxes.push(checkBoxName)
    } else {
      selectedCheckBoxes.push(checkBoxName)
    }
    servToSave.checkBoxes = selectedCheckBoxes
  }

  const handleSaveServ = async () => {
    if (servToSave.id) {
      try {
        setLoading(true)

        await updateDoc(doc(db, 'services', servToSave.id), {
          ...servToSave,
          image: imgUrl,
          updatedOn: new Date().toLocaleString() + '',
          updatedBy: loggedOnUser
            ? loggedOnUser.name
            : 'Dont know who updated this',
        })
        alert('Service Updated Successfully')
      } catch (error) {
        alert(error)
      }
    } else {
      try {
        const findDup = services.filter((serv) => serv.name === servToSave.name)

        findDup.length > 0
          ? alert('Duplicate Name - Cannot add a service with same name')
          : await addDoc(collection(db, 'services'), {
              ...servToSave,
              image: imgUrl,
              createdOn: new Date().toLocaleString() + '',
              createdBy: loggedOnUser
                ? loggedOnUser.name
                : 'Dont know who created this',
            })
        alert('Service Added Successfully')
        setServToSave({})
      } catch (error) {
        alert(error)
      }
    }
    setLoading(false)
    setServToSave({})
    setServModal(false)
  }

  const editService = (serv) => {
    setServToSave(serv)
    setHasRadioButtons(serv.radios && serv.radios.length > 0)
    setHasCheckBoxes(serv.checkBoxes && serv.checkBoxes.length > 0)
  }

  const handleFileUpload = async (e) => {
    const file = e.target.files[0]
    if (!file) return
    const storageRef = ref(storage, `services/${file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)
    try {
      setLoading(true)
      await uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
          setProgresspercent(progress)
        },
        (error) => {
          alert(error)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImgUrl(downloadURL)
          })
        }
      )
    } catch (error) {
      alert(error)
    }
    setLoading(false)
  }

  const deleteService = async (serv) => {
    await deleteDoc(doc(db, 'services', serv.id))

    const servRef = ref(storage, serv.image)

    // Delete the file
    deleteObject(servRef)
      .then(() => {
        alert(' File deleted successfully')
      })
      .catch((error) => {
        alert('Uh-oh, an error occurred!')
      })

    setLoading(false)
    setServToSave({})
    setServModal(false)
  }

  const handleCatSel = (catName) => {
    setSelected(catName)
    setServToSave({
      ...servToSave,
      mainCat: catName && catName[0] && catName[0].name,
    })

    const myCat = categories.filter(
      (cat) => cat.name === catName && catName[0] && catName[0].name
    )
    setImgUrl(myCat[0] ? myCat[0].image : '')
  }

  return (
    <>
      <h2 style={{ textAlign: 'center' }}>Manage Services </h2>
      <Col sm={12} style={{ textAlign: 'center' }}>
        Do you want to add a new service?{' '}
        <Button
          style={{
            padding: '1px',
            borderRadius: '30px',
            margin: '1px',
            width: '60px',
          }}
          onClick={() => {
            setServModal(true)
          }}
        >
          ADD
        </Button>
      </Col>
      {servModal && (
        <Container
          className='d-flex align-items-center justify-content-center'
          style={{ backgroundImage: { imgUrl } }}
        >
          <div className='w-100'>
            <Card>
              <Card.Body>
                <h2 className='text-center mb-4'>
                  {servToSave && servToSave.id
                    ? `Update Service ${servToSave.name} `
                    : `New Service`}
                </h2>
                Main Cat: {servToSave.mainCat}
                <Form.Check
                  type='checkbox'
                  label='Enabled?'
                  checked={servToSave && servToSave.enabled}
                  onChange={() =>
                    setServToSave({
                      ...servToSave,
                      enabled: !servToSave.enabled,
                    })
                  }
                />
                {!imgUrl && (
                  <ProgressBar
                    animated
                    now={progresspercent}
                    label={`${progresspercent}%`}
                  />
                )}
                <br />
                <Form>
                  <Form.Group>
                    {categories && (
                      <Typeahead
                        labelKey='name'
                        options={categories}
                        placeholder='Search for a category...'
                        style={{
                          borderColor: 'blue',
                        }}
                        ref={ref1}
                        id='basic-typeahead-single'
                        defaultInputValue={servToSave && servToSave.mainCat}
                        value={selected ? selected.name : ''}
                        onInputChange={(e) => {
                          handleCatSel(e)
                        }}
                        onChange={(e) => handleCatSel(e)}
                      />
                    )}
                  </Form.Group>
                  <br />
                  <Form.Group>
                    <Form.Control
                      type='text'
                      required
                      placeholder='Name'
                      defaultValue={servToSave && servToSave.name}
                      onChange={(e) =>
                        setServToSave({ ...servToSave, name: e.target.value })
                      }
                    />
                  </Form.Group>
                  <br />
                  <Form.Group>
                    <Form.Control
                      type='text'
                      required
                      placeholder='Description'
                      defaultValue={servToSave && servToSave.description}
                      onChange={(e) =>
                        setServToSave({
                          ...servToSave,
                          description: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <br />
                  <h4 style={{ textAlign: 'center', color: 'navyblue' }}>
                    {' '}
                    Add Options{' '}
                  </h4>
                  <div>
                    <Form.Group className='d-flex'>
                      <Container>
                        <Accordion defaultActiveKey='0'>
                          <Accordion.Item eventKey='1'>
                            <Accordion.Header>
                              Add Radio Buttons
                            </Accordion.Header>
                            <Accordion.Body>
                              {radioButtons &&
                                radioButtons.map((radio) => (
                                  <Form.Group key={radio.id}>
                                    <Form.Check
                                      type='checkbox'
                                      label={radio.name}
                                      name='radio'
                                      defaultChecked={
                                        servToSave &&
                                        servToSave.radios &&
                                        servToSave.radios.includes(radio.name)
                                      }
                                      onChange={() =>
                                        manageSelectedRadios(radio.name)
                                      }
                                    />
                                  </Form.Group>
                                ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Container>
                    </Form.Group>
                    <Form.Group className='d-flex'>
                      <Container>
                        <Accordion defaultActiveKey='0'>
                          <Accordion.Item eventKey='1'>
                            <Accordion.Header>Add Checkboxes</Accordion.Header>
                            <Accordion.Body>
                              {checkBoxes &&
                                checkBoxes.map((checkbox) => (
                                  <Form.Group key={checkbox.id}>
                                    <Form.Check
                                      type='checkbox'
                                      label={checkbox.name}
                                      defaultChecked={
                                        servToSave &&
                                        servToSave.checkBoxes &&
                                        servToSave.checkBoxes.includes(
                                          checkbox.name
                                        )
                                      }
                                      name='checkbox'
                                      onChange={() =>
                                        manageSelectedCheckBoxes(checkbox.name)
                                      }
                                    />
                                  </Form.Group>
                                ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Container>
                    </Form.Group>
                  </div>
                  <br />
                  <Col sm={4}>
                    <Form.Label>Image for Service</Form.Label>
                    {servToSave && (
                      <Image
                        style={{ width: '40px', height: '40px' }}
                        src={servToSave.image}
                        alt={servToSave.name}
                      />
                    )}{' '}
                  </Col>
                  <Form.Control
                    className='mb-2 mt-1'
                    type='file'
                    multiple
                    onChange={async (e) => {
                      setLoading(true)
                      await handleFileUpload(e)
                      setLoading(false)
                    }}
                  />
                  <div className='d-flex justify-content-center'>
                    <Button
                      disabled={loading}
                      className='w-100'
                      type='cancel'
                      onClick={() => setServModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={loading}
                      className='w-100'
                      onClick={() => handleSaveServ()}
                    >
                      {servToSave && servToSave.id ? 'Update' : 'Save'}
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </Container>
      )}
      <Table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Service Name</th>
            <th>Main Category</th>
            <th>Description</th>
            <th>Options</th>
            <th>Edit?</th>
          </tr>
        </thead>
        {services &&
          services.map((service) => (
            <tbody key={service.id}>
              <tr>
                <td>
                  <Image
                    style={{ width: '40px', height: '40px' }}
                    src={service.image}
                    alt={service.name}
                  />
                </td>
                <td>
                  <Link
                    to='#'
                    onClick={() => {
                      alert('Show Image in a popup')
                    }}
                  >
                    {service.name}
                  </Link>
                </td>
                <td>{service.mainCat}</td>
                <td>{service.description}</td>
                <td>
                  {service.options &&
                    service.options.map((option) => option.option + '; ')}
                </td>
                <td>
                  <Button
                    style={{
                      padding: '1px',
                      borderRadius: '30px',
                      margin: '1px',
                      width: '60px',
                    }}
                    onClick={() => {
                      editService(service)
                      setServModal(true)
                    }}
                  >
                    Edit{' '}
                  </Button>{' '}
                  <Button
                    variant='danger'
                    style={{
                      padding: '1px',
                      borderRadius: '30px',
                      margin: '1px',
                      width: '60px',
                    }}
                    onClick={async () => await deleteService(service)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            </tbody>
          ))}
      </Table>
    </>
  )
}

export default Services
