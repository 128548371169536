import { Card, Col, Container, Row } from 'react-bootstrap'
import { useAdmin } from '../../contexts/AdminContext'
import { useUsers } from '../../contexts/UsersContext'
import { useEffect, useState } from 'react'

const QuotesFeed = () => {
  const { quotes, orders } = useAdmin()
  const { loggedOnUser } = useUsers()
  const [filteredQuotes, setFilteredQuotes] = useState([])
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [totalQuotes, setTotalQuotes] = useState(0)
  const [selectedQuote, setSelectedQuote] = useState(null)

  useEffect(() => {
    if (loggedOnUser) {
      if (loggedOnUser.role === 'business') {
        setFilteredQuotes(
          quotes &&
            quotes.length > 0 &&
            quotes.filter((quote) => quote.from === loggedOnUser.email)
        )
        setTotalQuotes(
          quotes &&
            quotes.length > 0 &&
            quotes.filter(
              (quote) =>
                quote.orderId === (selectedOrder && selectedOrder.id) &&
                quote.from === loggedOnUser.email
            ).length
        )
      }
    }
  }, [loggedOnUser, quotes, orders, selectedOrder])
  return (
    <Container>
      <Row className='member-statistical'>
        <Col sm={4}>
          <div className='item ob-item'>
            <div className='ob-head'>
              <h3> Quotes Sent</h3>
              <span
                onClick={() => alert('Navigate to All quotes Page')}
                style={{
                  color: '#1d1d55',
                  fontFamily: 'Jost',
                  cursor: 'pointer',
                }}
              >
                View all
              </span>
            </div>

            {filteredQuotes &&
              filteredQuotes.map((quote) => (
                <div key={quote.id}>
                  <Card border='primary'>
                    <Card.Body border='primary' align='left'>
                      <Row>
                        <Col sm={10}>
                          <p className='my-p'>
                            Order ID: {quote.orderId} <br />
                            Date: {quote.createdAt}
                            <br />
                            Amount: £{quote.message}
                            <br />
                            Status:{' '}
                            <b
                              style={{
                                color:
                                  quote.status === 'Completed'
                                    ? 'green'
                                    : quote.status === 'Pending'
                                    ? 'red'
                                    : 'blue',
                              }}
                            >
                              {' '}
                              {quote.status}
                            </b>
                          </p>
                        </Col>
                        <Col sm={1}>
                          <br />
                          <br />
                          <i
                            onClick={() => {
                              setSelectedQuote(quote)
                              setSelectedOrder(
                                orders &&
                                  orders.find(
                                    (order) => order.id === quote.orderId
                                  )
                              )
                            }}
                            className='las la-angle-right'
                            style={{
                              fontSize: '24px',
                              color: '#1D1D55',
                              cursor: 'pointer',
                            }}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>{' '}
        </Col>
        <Col sm={8}>
          {selectedOrder && (
            <Card border='primary'>
              <Card.Body border='primary' align='left'>
                <Row>
                  <Col sm={10}>
                    <p className='my-p'>
                      Quote Amount: £{selectedQuote.message}
                      <br />
                      {selectedQuote.customerOffer &&
                        `Customer Offer: £${selectedQuote.customerOffer}`}
                      <br />
                      Job Details: <br />
                      Job ID: {selectedOrder.id} <br />
                      Created Date: {selectedOrder.createdAt}
                      <br />
                      Service Needed: {selectedOrder.serviceNeeded} of{' '}
                      {selectedOrder.categoryNeeded} category
                      <br />
                      Service Location:{' '}
                      <b style={{ color: 'darkgreen' }}>
                        {' '}
                        {selectedOrder.address &&
                          selectedOrder.address.city}{' '}
                        {selectedOrder.address &&
                          selectedOrder.address.postcode}
                      </b>
                      <br />
                      Options Selected: <br />
                      {selectedOrder.radios &&
                        selectedOrder.radios.length > 0 &&
                        selectedOrder.radios.map((radio, index) => (
                          <span key={index}>
                            {' '}
                            {radio.type}: {radio.option}
                            <br />
                          </span>
                        ))}
                      <br /> Items Selected: <br />
                      {selectedOrder.checks &&
                        selectedOrder.checks.length > 0 &&
                        selectedOrder.checks.map((check, index) => (
                          <span key={index}>
                            {' '}
                            {check.type}: {check.option}
                            <br />
                          </span>
                        ))}
                      <br />
                      Status:{' '}
                      {selectedOrder.supplier &&
                      selectedOrder.supplier !== loggedOnUser.email ? (
                        <b style={{ color: 'red' }}>Sorry, sold out</b>
                      ) : (
                        <b style={{ color: 'green' }}>{selectedOrder.status}</b>
                      )}
                      {selectedOrder.status !== 'Quote Accepted' && (
                        <p>
                          {' '}
                          To send new quote for this order, please select
                          Potential Orders tab and select the order from there.
                        </p>
                      )}
                      <br />
                      FYI: Customer received total quotes: {totalQuotes}{' '}
                      including this one.
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
          <br />
          <br />
        </Col>
      </Row>
    </Container>
  )
}

export default QuotesFeed
