import { initializeApp } from 'firebase/app'
import { getFirestore, Timestamp } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

// Project Owner : sasibindu0611@gmail.com - please send request to get access
const firebaseConfig = {
  apiKey: 'AIzaSyCL9yvcHDW1aLUyNc9p6DTNM1m9yGfyZBc',
  authDomain: 'omniseva-d94af.firebaseapp.com',
  projectId: 'omniseva-d94af',
  storageBucket: 'omniseva-d94af.appspot.com',
  messagingSenderId: '461435383333',
  appId: '1:461435383333:web:8c1bc069809c7f9e3959cc',
  // apiKey: 'AIzaSyBnv9A7n7NXRLsP4MMu_p_zWmpeK_2f-cA',
  // authDomain: 'dev-omniseva.firebaseapp.com',
  // projectId: 'dev-omniseva',
  // storageBucket: 'dev-omniseva.appspot.com',
  // messagingSenderId: '574183865818',
  // appId: '1:574183865818:web:36a99099b3e8c7e6c3d4e3',
  // measurementId: 'G-B7HXME5TDF',
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)

export { db, auth, storage, Timestamp }
