import { Col, Container, Row } from 'react-bootstrap'
import { useAdmin } from '../../contexts/AdminContext'
import { useUsers } from '../../contexts/UsersContext'

import './supplier.css'
import { useEffect, useState } from 'react'

const Summary = () => {
  const { myOrders, quotes, messages } = useAdmin()
  const { loggedOnUser } = useUsers()
  const [myQuotes, setMyQuotes] = useState([])
  const [myMessages, setMyMessages] = useState([])
  const [rcvdMessages, setRcvdMessages] = useState([])
  const { setClickedButton } = useAdmin()

  useEffect(() => {
    if (loggedOnUser) {
      if (loggedOnUser.role === 'personal') {
        setMyQuotes(
          quotes && quotes.filter((quote) => quote.to === loggedOnUser.email)
        )
        setMyMessages(
          messages &&
            messages.filter((message) => message.from === loggedOnUser.email)
        )
        setRcvdMessages(
          messages &&
            messages.filter((message) => message.to === loggedOnUser.email)
        )
      }
    }
  }, [loggedOnUser, quotes, messages])

  return (
    <>
      <Container>
        <Row className='member-statistical'>
          <Col sm={3}>
            <div className='item blue'>
              <h3>Orders Placed by Me</h3>
              <span
                className='number my_link'
                onClick={() => setClickedButton('My Orders')}
              >
                {myOrders && myOrders.length}
              </span>{' '}
              <span className='line'></span>
            </div>
          </Col>
          <Col sm={3}>
            <div className='item green'>
              <h3>Messages</h3>
              <span
                className='number my_link'
                onClick={() => setClickedButton('Messages')}
              >
                Sent: {myMessages ? myMessages.length : 0} <br /> Rcvd:{' '}
                {rcvdMessages ? rcvdMessages.length : 0}
              </span>{' '}
              {/* Get from DB */}
              <span className='line'></span>
            </div>
          </Col>{' '}
          <Col sm={3}>
            <div className='item yellow'>
              <h3>Quotes Received</h3>
              <span
                className='number my_link'
                onClick={() => setClickedButton('Quotes Received')}
              >
                {myQuotes ? myQuotes.length : 0}{' '}
              </span>{' '}
              {/* Get from DB */}
              <span className='line'></span>
            </div>
          </Col>{' '}
          <Col sm={3}>
            <div className='item purple'>
              <h3>Work in Progress</h3>
              <span
                className='number my_link'
                onClick={() => alert('Work in Progress')}
              >
                0
              </span>{' '}
              {/* Get from DB */}
              <span className='line'></span>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Summary
