import 'bootstrap/dist/css/bootstrap.css'

import './App.css'
import './resources/css/style.css'
import './resources/css/responsive.css'
import './resources/css/custom.css'

import Header from './components/Header'
import Home from './pages/public/Home'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import SuppProfileHeader from './components/supplier/SuppProfileHeader'
import SupplierDashboard from './components/supplier/SupplierDashboard'
import AdminHeader from './components/admin/AdminHeader'
import AdminDashboard from './pages/admin/AdminDashboard'
import AdminProvider from './contexts/AdminContext'
import Categories from './components/admin/Categories'
import Services from './components/admin/Services'
import Customers from './components/admin/Customer'
import Suppliers from './components/admin/Suppliers'
import Login from './components/auth/Login'
import Register from './components/auth/Register'
import AdminLogin from './components/auth/AdminLogin'
import { useAuthContext } from './hooks/useAuthContext'
import PersonalDashboard from './components/personal/PersonalDashboard'
import { useUsers } from './contexts/UsersContext'
import PersonalRoleError from './components/personal/PersonalRoleError'
import BusinessRoleError from './components/supplier/BusinessRoleError'
import Footer from './components/Footer'
import LiveJobs from './components/LiveJobs'
import PersonalDetails from './components/supplier/PersonalDetails'
import BusinessDetails from './components/supplier/BusinessDetails'
import MyAccount from './components/personal/MyAccount'

function App() {
  const { user } = useAuthContext()
  const { loggedOnUser } = useUsers()
  // Startting Point
  return (
    <div className=''>
      <AdminProvider>
        <Router>
          {loggedOnUser && loggedOnUser.role.toLowerCase() === 'admin' ? (
            <AdminHeader />
          ) : loggedOnUser && loggedOnUser.role.toLowerCase() === 'business' ? (
            <SuppProfileHeader />
          ) : (
            <Header />
          )}
          <br />
          <br />

          <Routes>
            <Route path='/' element={<Home />} />
            <Route
              path='/business'
              element={
                !user ? (
                  <Login />
                ) : loggedOnUser && loggedOnUser.role !== 'business' ? (
                  <BusinessRoleError />
                ) : (
                  <SupplierDashboard />
                )
              }
            />
            <Route
              path='/person'
              element={
                !user ? (
                  <Login />
                ) : loggedOnUser && loggedOnUser.role !== 'personal' ? (
                  <PersonalRoleError />
                ) : (
                  <PersonalDashboard />
                )
              }
            />
            <Route
              path='/admin'
              element={
                !user ? (
                  <AdminLogin />
                ) : loggedOnUser && loggedOnUser.role !== 'admin' ? (
                  <AdminLogin />
                ) : (
                  <AdminDashboard />
                )
              }
            />

            <Route path='/admin/services' element={<Services />} />
            <Route path='/admin/categories' element={<Categories />} />
            <Route path='/admin/customers' element={<Customers />} />
            <Route path='/admin/suppliers' element={<Suppliers />} />
            <Route path='/login' element={!user ? <Login /> : <Home />} />
            <Route path='/register' element={!user ? <Register /> : <Home />} />

            <Route
              path='/personal/myaccount'
              element={
                !user ? (
                  <Login />
                ) : loggedOnUser && loggedOnUser.role !== 'personal' ? (
                  <PersonalRoleError />
                ) : (
                  <MyAccount />
                )
              }
            />
            <Route
              path='/business/personaldetails'
              element={
                !user ? (
                  <Login />
                ) : loggedOnUser && loggedOnUser.role !== 'business' ? (
                  <BusinessRoleError />
                ) : (
                  <PersonalDetails />
                )
              }
            />
            <Route
              path='/business/companydetails'
              element={
                !user ? (
                  <Login />
                ) : loggedOnUser && loggedOnUser.role !== 'business' ? (
                  <BusinessRoleError />
                ) : (
                  <BusinessDetails />
                )
              }
            />
            <Route path='*' element={<h1>404: Not Found</h1>} />
          </Routes>
          {/* <Routes>
            <Route path='/'  element={<Home />} />
          </Routes> */}
          {/* <Footer /> */}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <LiveJobs />
          <Footer />
        </Router>
      </AdminProvider>
    </div>
  )
}

export default App
