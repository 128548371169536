import { Container, Row } from 'react-bootstrap'
import Announcement from './Announcement'
import Feeds from './Feeds'
import Summary from './Summary'

const PersonalDashboard = () => {
  return (
    <div
      style={{
        backgroundColor: '#ededff',
      }}
    >
      <Container>
        <Row>
          <Announcement />
        </Row>
        <Row>
          <Summary />
        </Row>
        <Row>
          <Feeds />
        </Row>
      </Container>
    </div>
  )
}

export default PersonalDashboard
