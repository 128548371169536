import { Container, Row, Col } from 'react-bootstrap'
import OrdersFeed from './OrdersFeed'
import MessagesFeed from './MessagesFeed'
import { useAdmin } from '../../contexts/AdminContext'
import QuotesFeed from './QuotesFeed'
import WorkInProgress from './WorkInProgress'

const Feeds = () => {
  const { clickedLink } = useAdmin()
  return (
    <Container>
      <Row className='member-statistical'>
        <Col sm={12}>
          {clickedLink === 'Potential Orders' && <OrdersFeed />}
          {clickedLink === 'Quotes Sent' && <QuotesFeed />}
          {clickedLink === 'Messages' && <MessagesFeed />}
          {clickedLink === 'WIP' && <WorkInProgress />}
        </Col>
        <Col sm={4}></Col>

        <Col sm={4}></Col>
      </Row>
    </Container>
  )
}

export default Feeds
