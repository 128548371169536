import { useState, useEffect } from "react";
import {db,storage} from '../../config/Firebase'
import { Link } from 'react-router-dom'
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import {
  Table,
  Col,
  Button,
  Image,
  Modal,
  Container,
  Card,
  Form,
  ProgressBar,
} from 'react-bootstrap'
import { useAdmin } from "../../contexts/AdminContext";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'

const Customers = () => 
{
  const [loading, setLoading] = useState(false)
  const [userModal, setUserModal] = useState()
  const [usertoSave, setUsertoSave] = useState({})
  const {allUsers} = useAdmin()


  const  addUser = async () => {
    setUserModal(true)
  };
  const handleSaveUser = async (e) => {
    e.preventDefault()
    if (usertoSave.id) {
      try {
        setLoading(true)
        await updateDoc(doc(db, 'users', usertoSave.id), {
          ...usertoSave,
        })
        alert('User Updated Successfully')
      } catch (error) {
        alert(error)
      }
    } else {
      try {
        const findDup = allUsers.filter((user) => user.name === usertoSave.name)
        findDup.length > 0
          ? alert('Duplicate Name - Cannot add a user with same name')
          : await addDoc(collection(db, 'users'), {
              ...usertoSave,
            })
      } catch (error) {
        alert(error)
      }
    }
    setLoading(false)
    setUsertoSave({})
    setUserModal(false)
  }
  const updateUser = async (id,email,mobile,Address1,Address2,postcode,role) => {
    const userDoc = doc(db, "users", id);
    const newFields = { email:email,mobile:mobile,Address1:Address1,Address2:Address2,postcode:postcode,role:role };
    await updateDoc(userDoc, newFields);
  };
 
  const deleteUser = async (user) => {
    const userDoc = doc(db, "users", user.id);
    console.log(user.id);
    await deleteDoc(userDoc);
  };

   return (
    <>
      Manage Customers
      <Col sm={10}>
        Do you want to add a new User?{' '}
        <Button
          style={{
            padding: '1px',
            borderRadius: '30px',
            margin: '1px',
            width: '60px',
          }}
          onClick={() => {
            addUser()
          }}
        >
          ADD
        </Button>
      </Col>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Address1</th>
            <th>Address2</th>
            <th>Postcode</th>
            <th>Role</th>
            <th>Edit?</th>
          </tr>
        </thead>
        {allUsers &&
          allUsers.map((user) => (
            <tbody key={user.id}>
              <tr>
              <td>
                   {user.name}
                </td>
                <td>
                   {user.email}
                </td>
                <td>
                  {user.mobile}
                </td>
                <td>{user.Address1}</td>
                <td>
                  {user.Address2}
                </td>
                <td>
                   {user.postcode}
                </td>
                <td>
                  {user.role}
                </td>
               
                <td>
                  <Button
                    style={{
                      padding: '1px',
                      borderRadius: '30px',
                      margin: '1px',
                      width: '60px',
                    }}
                    onClick={() => {
                      setUsertoSave(user)
                      setUserModal(true)
                    }}
                  >
                    Edit{' '}
                  </Button>{' '}
                  <Button
                    variant='danger'
                    style={{
                      padding: '1px',
                      borderRadius: '30px',
                      margin: '1px',
                      width: '60px',
                    }}
                    onClick={async () => await deleteUser(user)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            </tbody>
          ))}
      </Table>
      <Modal
        show={userModal}
        onHide={() => {
          setUsertoSave({})
          setUserModal(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Container
          className='d-flex align-items-center justify-content-center'
          style={{ minHeight: '60vh' }}
        >
          <div className='w-100' style={{ maxWidth: '400px' }}>
            <Card>
              <Card.Body>
                <h2 className='text-center mb-4'>
                  {usertoSave && usertoSave.id
                    ? 'Update User'
                    : 'New User'}
                </h2>
               
                <br />
                <Form onSubmit={handleSaveUser}>
                  <Form.Group id='name'>
                    <Form.Control
                      type='text'
                      required
                      placeholder='Name'
                      defaultValue={usertoSave && usertoSave.name}
                      onChange={(e) =>
                        setUsertoSave({ ...usertoSave, name: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Form.Group id='desc'>
                    <Form.Control
                      type='email'
                      required
                      placeholder='Email'
                      defaultValue={usertoSave && usertoSave.email}
                      onChange={(e) =>
                        setUsertoSave({
                          ...usertoSave,
                          email: e.target.value,
                        })
                      }
                    />
                  </Form.Group>{' '}
                  <Form.Group id='desc'>
                    <Form.Control
                      type='phone'
                      required
                      placeholder='Mobile'
                      defaultValue={usertoSave && usertoSave.mobile}
                      onChange={(e) =>
                        setUsertoSave({
                          ...usertoSave,
                          mobile: e.target.value,
                        })
                      }
                    />
                  </Form.Group>{' '}
                  <Form.Group id='desc'>
                    <Form.Control
                      type='text'
                      required
                      placeholder='Address1'
                      defaultValue={usertoSave && usertoSave.Address1}
                      onChange={(e) =>
                        setUsertoSave({
                          ...usertoSave,
                          Address1: e.target.value,
                        })
                      }
                    />
                  </Form.Group>{' '}
                  <Form.Group id='desc'>
                    <Form.Control
                      type='text'
                      required
                      placeholder='Address2'
                      defaultValue={usertoSave && usertoSave.Address2}
                      onChange={(e) =>
                        setUsertoSave({
                          ...usertoSave,
                          Address2: e.target.value,
                        })
                      }
                    />
                  </Form.Group>{' '}
                  <Form.Group id='desc'>
                    <Form.Control
                      type='text'
                      required
                      placeholder='Postcode'
                      defaultValue={usertoSave && usertoSave.postcode}
                      onChange={(e) =>
                        setUsertoSave({
                          ...usertoSave,
                          postcode: e.target.value,
                        })
                      }
                    />
                  </Form.Group>{' '}
                  <Form.Group id='desc'>
                    <Form.Control
                      type='text'
                      required
                      placeholder='Role'
                      defaultValue={usertoSave && usertoSave.role}
                      onChange={(e) =>
                        setUsertoSave({
                          ...usertoSave,
                          role: e.target.value,
                        })
                      }
                    />
                  </Form.Group>{' '}
                  
                  <Button disabled={loading} className='w-100' type='submit'>
                    {/* <Button className='w-100' type='submit'> */}
                    {usertoSave && usertoSave.id ? 'Update' : 'Save'}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </Modal>
    </>
  )
      
      
  
  
  
  
  
}

export default Customers