import { Card, Col, Container, Form, Row } from 'react-bootstrap'
import { useAdmin } from '../../contexts/AdminContext'
import { useUsers } from '../../contexts/UsersContext'
import { useEffect, useState } from 'react'
import OrderDetails from './OrderDetails'

const OrdersFeed = () => {
  const { quotes, orders } = useAdmin()
  const { loggedOnUser } = useUsers()
  const [citiesOffered, setCitiesOffered] = useState([])
  const [servicesOffered, setServicesOffered] = useState([])
  const [filteredOrders, setFilteredOrders] = useState([])
  const [selectedOrder, setSelectedOrder] = useState(null)

  useEffect(() => {
    if (loggedOnUser) {
      if (loggedOnUser.role === 'business') {
        setCitiesOffered(loggedOnUser.citiesOffered)
        setServicesOffered(loggedOnUser.servicesOffered)
        setFilteredOrders(
          orders && orders.filter((order) => order.status === 'New')
        )
      }
    }
  }, [loggedOnUser, quotes, orders])

  return (
    <Container>
      <Row className='member-statistical'>
        <Col sm={4}>
          <div className='item ob-item'>
            <div className='ob-head'>
              <h3>Potential Orders</h3>
              {/* <span
          onClick={() => alert('Navigate to All quotes Page')}
          style={{
            color: '#1d1d55',
            fontFamily: 'Jost',
            cursor: 'pointer',
          }}
        >
          View all
        </span> */}
            </div>
            <Form.Select
              className='my-select'
              defaultValue={'AAA'}
              onChange={(e) => {
                e.target.value === ''
                  ? setFilteredOrders(orders && orders)
                  : setFilteredOrders(
                      orders &&
                        orders.filter(
                          (order) => order.address.city === e.target.value
                        )
                    )
              }}
            >
              <option value='AAA'>Select City</option>
              {citiesOffered &&
                citiesOffered.map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              <option value=''>All over UK</option>
            </Form.Select>
            <Form.Select
              as='select'
              className='my-select'
              defaultValue={'AAA'}
              onChange={(e) => {
                e.target.value === ''
                  ? setFilteredOrders(orders && orders)
                  : setFilteredOrders(
                      orders &&
                        orders.filter(
                          (order) => order.categoryNeeded === e.target.value
                        )
                    )
              }}
            >
              <option value='AAA'>Select category</option>
              {servicesOffered &&
                servicesOffered.map((service) => (
                  <option key={service} value={service}>
                    {service}
                  </option>
                ))}
              <option value=''>All categories</option>
            </Form.Select>

            {filteredOrders &&
              filteredOrders.map((order) => (
                <div key={order.id}>
                  <Card border='primary'>
                    <Card.Body border='primary' align='left'>
                      <Row>
                        <Col sm={10}>
                          <p className='my-p1'>
                            Date Created: {order && order.createdAt}
                            <br />
                            Description: {order.serviceNeeded}
                            <br />
                            Category: {order.categoryNeeded}
                            <br />
                            Location: {order.address.city}
                            <br />
                            Status:{' '}
                            <b
                              style={{
                                color:
                                  order.status === 'Completed'
                                    ? 'green'
                                    : order.status === 'Pending'
                                    ? 'red'
                                    : 'blue',
                              }}
                            >
                              {' '}
                              {order.status}
                            </b>
                          </p>
                        </Col>
                        <Col sm={1}>
                          <br />
                          <br />
                          <i
                            onClick={() =>
                              // navigate(`/business/orders/${order.id}`, {
                              //   state: { order },
                              // })
                              setSelectedOrder(order)
                            }
                            className='las la-angle-right'
                            style={{
                              fontSize: '24px',
                              color: '#1D1D55',
                              cursor: 'pointer',
                            }}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
        </Col>
        {selectedOrder && (
          <Col sm={8}>
            <Card border='primary'>
              <Card.Body border='primary' align='left'>
                <Row>
                  <Col sm={10}>
                    <OrderDetails order={selectedOrder} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  )
}

export default OrdersFeed
