import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { useNavigate } from 'react-router-dom'
import { useAdmin } from '../../contexts/AdminContext'

const AdminHeader = () => {
  const navigate = useNavigate()
  const { error } = useAdmin()

  return (
    <Navbar className='my-nav' expand='lg'>
      <Container>
        {error && <div className='alert alert-danger'>{error}</div>}
        <Navbar.Toggle aria-controls='supp-header' />
        <Navbar.Collapse id='supp-header'>
          <Nav
            defaultActiveKey={'SupplierProfile'}
            //   onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
          >
            <Nav.Link style={{color:"white"}}
              onClick={() => navigate('/admin')}
              eventKey={'SupplierProfile'}
              className='my-link'
            >
              Admin Dashboard
            </Nav.Link>{' '}
            <Nav.Link
              className='my-link'
              eventKey='ManageCategories'
              onClick={() => navigate('/admin/categories')}
            >
              Categories
            </Nav.Link>{' '}
            <Nav.Link
              className='my-link'
              eventKey='ManageServices'
              onClick={() => navigate('/admin/services')}
            >
              Services
            </Nav.Link>
            <Nav.Link
              className='my-link'
              eventKey='ManageCustomers'
              onClick={() => navigate('/admin/customers')}
            >
              Customers
            </Nav.Link>{' '}
            <Nav.Link
              className='my-link'
              eventKey='ManageSuppliers'
              onClick={() => navigate('/admin/suppliers')}
            >
              Suppliers
            </Nav.Link>{' '}
            {/* <Nav.Link className='my-link' eventKey='ManageChat'>
              Manage Chat
            </Nav.Link>{' '}
            <Nav.Link className='my-link' eventKey='Disputes'>
              Disputes
            </Nav.Link>{' '}
            <Nav.Link className='my-link' eventKey='Accounting'>
              Accounting
            </Nav.Link>{' '} */}
            <Nav.Link style={{ paddingRight: '30px' , color:"white" }} navigate='/business'>
              Business
            </Nav.Link>
            <Nav.Link style={{ paddingRight: '30px' , color:"white"}} navigate='/'>
              Home
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default AdminHeader
