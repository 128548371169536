import { useState } from 'react'
import { db, storage } from '../../config/Firebase'
import { Link } from 'react-router-dom'
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from 'firebase/firestore'
import {
  Table,
  Col,
  Button,
  Image,
  Modal,
  Container,
  Card,
  Form,
  ProgressBar,
} from 'react-bootstrap'
import { useAdmin } from '../../contexts/AdminContext'
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import { useUsers } from '../../contexts/UsersContext'

const Categories = () => {
  const [catModal, setCatModal] = useState()
  const [catToSave, setCatToSave] = useState({})
  const [imgUrl, setImgUrl] = useState()
  const [loading, setLoading] = useState(false)
  const [progresspercent, setProgresspercent] = useState(0)
  const { loggedOnUser } = useUsers()
  const { categories, services } = useAdmin()

  const addCategory = async () => {
    setCatModal(true)
  }
  const handleSaveCat = async (e) => {
    e.preventDefault()
    if (catToSave.id) {
      try {
        setLoading(true)
        await updateDoc(doc(db, 'categories', catToSave.id), {
          ...catToSave,
          image: imgUrl ? imgUrl : catToSave.image,
          modifiedOn: new Date().toLocaleString() + '',
          updatedBy: loggedOnUser && loggedOnUser.name,
        })
        alert('Category Updated Successfully')
      } catch (error) {
        alert(error)
      }
    } else {
      try {
        const findDup = categories.filter((cat) => cat.name === catToSave.name)
        if (imgUrl) {
          findDup.length > 0
            ? alert('Duplicate Name - Cannot add a category with same name')
            : await addDoc(collection(db, 'categories'), {
                ...catToSave,
                image: imgUrl,
                createdOn: new Date().toLocaleString() + '',
                createdBy: loggedOnUser && loggedOnUser.name,
              })
        } else {
          alert('Please upload an image')
        }
      } catch (error) {
        alert(error)
      }
    }
    setLoading(false)
    setCatToSave({})
    setCatModal(false)
  }

  const handleFileUpload = async (e) => {
    const file = e.target.files[0]

    if (!file) return
    const storageRef = ref(storage, `categories/${file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)
    try {
      setLoading(true)
      await uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
          setProgresspercent(progress)
        },
        (error) => {
          alert(error)
          console.log(error)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImgUrl(downloadURL)
          })
        }
      )
    } catch (error) {
      console.log(error)
      alert(error)
    }
    setLoading(false)
  }

  const deleteCatgegory = async (category) => {
    const categoryDoc = doc(db, 'categories', category.id)
    await deleteObject(ref(storage, category.image))
    await deleteDoc(categoryDoc)
  }

  return (
    <>
      Manage Categories
      <Col sm={10}>
        Do you want to add a new category?{' '}
        <Button
          style={{
            padding: '1px',
            borderRadius: '30px',
            margin: '1px',
            width: '60px',
          }}
          onClick={() => {
            addCategory()
          }}
        >
          ADD
        </Button>
      </Col>
      <Table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Category Name</th>
            <th>Description</th>
            <th></th>
            <th># Services</th>
            <th></th>
          </tr>
        </thead>
        {categories &&
          categories.map((category) => (
            <tbody key={category.id}>
              <tr>
                <td>
                  <Link to={{ pathname: category.image }} target='_blank'>
                    <Image
                      style={{ width: '40px', height: '40px' }}
                      src={category.image}
                      alt={category.name}
                    />
                  </Link>
                </td>
                <td>{category.name}</td>
                <td>{category.description}</td>
                <td></td>

                <td>
                  {
                    services.filter(
                      (service) => service.mainCat === category.name
                    ).length
                  }
                </td>
                <td>
                  <Button
                    style={{
                      padding: '1px',
                      borderRadius: '30px',
                      margin: '1px',
                      width: '60px',
                    }}
                    onClick={() => {
                      setCatToSave(category)
                      setCatModal(true)
                    }}
                  >
                    Edit{' '}
                  </Button>{' '}
                  <Button
                    variant='danger'
                    style={{
                      padding: '1px',
                      borderRadius: '30px',
                      margin: '1px',
                      width: '60px',
                    }}
                    onClick={async () => await deleteCatgegory(category)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            </tbody>
          ))}
      </Table>
      <Modal
        show={catModal}
        onHide={() => {
          setCatToSave({})
          setCatModal(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        {!imgUrl && (
          <ProgressBar
            animated
            now={progresspercent}
            label={`${progresspercent}%`}
          />
        )}
        <Container
          className='d-flex align-items-center justify-content-center'
          style={{ minHeight: '60vh' }}
        >
          <div className='w-100' style={{ maxWidth: '400px' }}>
            <Card>
              <Card.Body>
                <h2 className='text-center mb-4'>
                  {catToSave && catToSave.id
                    ? 'Update Category'
                    : 'New Category'}
                </h2>

                <br />
                <Form onSubmit={handleSaveCat}>
                  <Form.Group id='name'>
                    <Form.Control
                      type='text'
                      required
                      placeholder='Name'
                      defaultValue={catToSave && catToSave.name}
                      onChange={(e) =>
                        setCatToSave({ ...catToSave, name: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Form.Group id='desc'>
                    <Form.Control
                      type='text'
                      required
                      placeholder='Description'
                      defaultValue={catToSave && catToSave.description}
                      onChange={(e) =>
                        setCatToSave({
                          ...catToSave,
                          description: e.target.value,
                        })
                      }
                    />
                  </Form.Group>{' '}
                  <Form.Label>Image for Category</Form.Label>
                  {catToSave && (
                    <Image
                      style={{ width: '20px', height: '20px' }}
                      src={catToSave.image}
                      alt={catToSave.name}
                    />
                  )}{' '}
                  <Form.Control
                    className='mb-2 mt-1'
                    type='file'
                    multiple
                    onChange={async (e) => {
                      setLoading(true)
                      await handleFileUpload(e)
                      setLoading(false)
                    }}
                  />
                  <Button disabled={loading} className='w-100' type='submit'>
                    {/* <Button className='w-100' type='submit'> */}
                    {catToSave && catToSave.id ? 'Update' : 'Save'}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </Modal>
    </>
  )
}

export default Categories
