import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import React, { useState, useEffect, createRef } from 'react'
import Slider from 'react-slick'
import { useAdmin } from '../contexts/AdminContext'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import {
  Button,
  Card,
  Row,
  Col,
  Container,
  Image,
  Modal,
  Form,
  Alert,
  textbox,
} from 'react-bootstrap'
import { useAuthContext } from '../hooks/useAuthContext'

import { addDoc, collection } from 'firebase/firestore'
import { db } from '../config/Firebase'
import { useUsers } from '../contexts/UsersContext'

const Section3 = () => {
  const {
    services,
    selectedCategory,
    error,
    setError,
    radioButtons,
    checkBoxes,
    selectedServ,
    citiesOffered,
  } = useAdmin()
  const [showDetailPage, setShowDetailPage] = useState(false)
  const [newJob, setNewJob] = useState({})
  const { user, error: authError } = useAuthContext()
  const [selectedRadios, setSelectedRadios] = useState([])
  const [selectedChecks, setSelectedChecks] = useState([])
  const [showConfirmQuoteDetails, setShowConfirmQuoteDetails] = useState(false)
  const [servicesToDisplay, setServicesToDisplay] = useState([])
  const [selectedService, setSelectedService] = useState({})
  const { loggedOnUser } = useUsers()
  const [showAddressForm, setShowAddressForm] = useState(false)
  const [startDate, setStartDate] = useState(new Date())

  const [address, setAddress] = useState({})

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  useEffect(() => {
    if (selectedCategory) {
      const filterServices =
        services &&
        services.filter(
          (service) =>
            service.enabled &&
            service.mainCat === selectedCategory &&
            service.name.includes(selectedServ ? selectedServ : '')
        )
      setServicesToDisplay(filterServices)
    } else {
      setServicesToDisplay(
        services &&
          services.filter(
            (service) =>
              service.enabled &&
              service.name.includes(selectedServ ? selectedServ : '')
          )
      )
    }
  }, [selectedCategory, services, selectedServ])

  useEffect(() => {
    if (loggedOnUser && loggedOnUser.address) {
      setAddress({
        address: loggedOnUser && loggedOnUser.address,
        city: loggedOnUser && loggedOnUser.city,
        postcode: loggedOnUser && loggedOnUser.postcode,
      })
    }
  }, [loggedOnUser])

  const submitHandler = async () => {
    setShowConfirmQuoteDetails(false)
    try {
      await addDoc(collection(db, 'orders'), {
        ...newJob,
        address: address,
        requesteddate: startDate.toLocaleString() + '',
        createdAt: new Date().toLocaleString() + '',
      })
      alert(
        'Job Posted Successfully. Please wait for quote from your local supplier'
      )
      setNewJob({})
      setSelectedChecks([])
      setSelectedRadios([])
      setAddress()
    } catch (error) {
      alert(error)
    }
    setShowDetailPage(false)
  }

  const confirmQuote = () => {
    setShowConfirmQuoteDetails(true)
    setNewJob((oldJob) => ({
      ...oldJob,
      checks: selectedChecks,
      radios: selectedRadios,
      categoryNeeded: selectedService && selectedService.mainCat,
      serviceNeeded: selectedService && selectedService.name,
      createdBy: loggedOnUser && loggedOnUser.email,
      customerName: loggedOnUser && loggedOnUser.name,
      address: address,
      requesteddate: startDate,
      status: 'New',
    }))
  }
  const backHandler = () => {
    setShowConfirmQuoteDetails(false)
    setShowDetailPage(true)
  }

  const manageSelectedChecks = (checkType, option) => {
    let checksSelectedcopy = []
    checksSelectedcopy = selectedChecks
    if (selectedCategory === false) {
      checksSelectedcopy = selectedChecks.filter((cb) => cb.option !== option)
    } else {
      let dup1 = selectedRadios.filter(
        (cb) => cb.type === checkType && cb.option === option
      )
      if (dup1.length === 0)
        checksSelectedcopy.push({ type: checkType, option })
    }
    setSelectedChecks(checksSelectedcopy)

    // let checksFilter = []
    // let dup1 = selectedChecks.filter((cb) => cb.type === checkType)
    // checksFilter = selectedChecks
    // if (dup1.length > 0) {
    //   checksFilter = selectedChecks.filter((cb) => cb.type !== checkType)
    // }

    // checksFilter.push({ type: checkType, option })
    // setSelectedChecks(checksFilter)

    // let checksSelectedCopy = []
    // checksSelectedCopy = selectedChecks
    // if (value === false) {
    //   checksSelectedCopy = selectedChecks.filter((cb) => cb.option !== option)
    // } else {
    //   let dup1 = selectedRadios.filter(
    //     (cb) => cb.type === checkBox && cb.option === option
    //   )
    //   if (dup1.length === 0) checksSelectedCopy.push({ type: checkBox, option })
    // }
    // setSelectedChecks(checksSelectedCopy)
  }

  const manageSelectedRadios = (radioType, option) => {
    let radiosFilter = []
    let dup1 = selectedRadios.filter((cb) => cb.type === radioType)
    radiosFilter = selectedRadios
    if (dup1.length > 0) {
      radiosFilter = selectedRadios.filter((cb) => cb.type !== radioType)
    }

    radiosFilter.push({ type: radioType, option })
    setSelectedRadios(radiosFilter)
  }

  const radios =
    selectedService &&
    radioButtons &&
    selectedService.radios &&
    selectedService.radios.map((radio) =>
      radioButtons.filter((button) => button.name === radio)
    )

  const checkboxes =
    selectedService &&
    checkBoxes &&
    selectedService.checkBoxes &&
    selectedService.checkBoxes.map((check) =>
      checkBoxes.filter((button) => button.name === check)
    )

  const findServiceAvailability = (e, post_town) => {
    e.preventDefault()
    const serviceAvailable =
      post_town && citiesOffered.filter((city) => city === post_town)
    if (serviceAvailable.length === 0) {
      alert(
        'Sorry the selected service is not available in your town. \n But you still can post the job and we will try to find a supplier for you.'
      )
    }
    setShowAddressForm(false)
  }
  return (
    <>
      <div
        className='site-banner'
        style={{
          backgroundColor: '#ededff',
        }}
      >
        {error && error !== '' && <Alert variant='danger'>{error}</Alert>}
        {authError && <Alert variant='danger'>{authError}</Alert>}
        <Container>
          <div className='title-wrap align-center'>
            <h2 className='title title-border-bottom align-center'>
              Select the service you need
            </h2>
          </div>
          <Slider className='slider-pd30' {...settings}>
            {servicesToDisplay && servicesToDisplay.length > 0 ? (
              servicesToDisplay.map((item) => (
                <div className='place-item layout-02 place-hover' key={item.id}>
                  <div className='place-inner'>
                    <div className='place-thumb hover-img'>
                      <img src={item.image} alt='' />
                      <div>
                        <Button
                          onClick={() => {
                            user
                              ? setShowDetailPage(true)
                              : // user.emailVerified
                                //   ? setShowDetailPage(true)
                                //   : alert(
                                //       'Please verify your email - check in spam as well'
                                //     )
                                alert('Please login to continue')
                            setSelectedService(item)
                          }}
                        >
                          Select this
                        </Button>
                      </div>
                    </div>
                    <div className='entry-detail'>
                      <div className='entry-head'>
                        <div className='place-type list-item'></div>
                      </div>
                      <h3 className='place-title'>{item.name}</h3>
                      {item.description}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h3>
                Coming soon.. please register your interest by sending us an
                email
              </h3>
            )}
          </Slider>
        </Container>
        <Modal
          show={showDetailPage}
          onHide={() => {
            setShowDetailPage(false)
            setAddress()
          }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Title>{selectedService && selectedService.name}</Modal.Title>
          <Modal.Body>
            {selectedService && (
              <Row>
                <Col md={12}>
                  <Form>
                    <Form.Group>
                      <Col md={12}>
                        <Image variant='top' src={selectedService.image} />
                        <Card.Title>{selectedService.mainService}</Card.Title>
                      </Col>
                    </Form.Group>

                    <Form.Text>
                      Address where the service is Needed:{' '}
                      {address && address.city + ' ' + address.postcode}
                    </Form.Text>
                    <Form.Text>
                      <br />
                      Do you need at a different address?
                      <Button onClick={() => setShowAddressForm(true)}>
                        Yes
                      </Button>
                    </Form.Text>
                    {showAddressForm && (
                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Col md={12}>
                          <Form.Group>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type='text'
                              placeholder='Enter address'
                              required
                              value={address && address.address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                              type='text'
                              required
                              placeholder='Enter city'
                              value={address && address.city && address.city}
                              onChange={(e) =>
                                setAddress({
                                  ...address,
                                  city: e.target.value,
                                })
                              }
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Postcode</Form.Label>
                            <Form.Control
                              type='text'
                              required
                              placeholder='Enter postcode'
                              value={
                                address && address.postcode && address.postcode
                              }
                              onChange={(e) =>
                                setAddress({
                                  ...address,
                                  postcode: e.target.value,
                                })
                              }
                            />
                          </Form.Group>
                          <Button
                            type='submit'
                            variant='primary'
                            onClick={(e) => {
                              findServiceAvailability(e, address.city)
                            }}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    )}
                    <br />
                    <br />
                    <Form.Group>
                      <br />
                      <Form.Label>Pick the date</Form.Label>
                      <Form.Control
                        type='date'
                        placeholder='Enter date'
                        required
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Form.Group>

                    <br />
                    <br />
                    <br />
                    <br />

                    {radios &&
                      radios.map((radio) => (
                        <Form.Group key={radio[0].id}>
                          <h6>{radio[0].name}</h6>
                          {radio[0] &&
                            radio[0].options &&
                            radio[0].options.map((option, index) => (
                              <Form.Group className='d-inline-flex' key={index}>
                                <Form.Check
                                  type='radio'
                                  label={option}
                                  name={radio[0].name}
                                  onChange={(e) =>
                                    manageSelectedRadios(
                                      radio[0].name,
                                      option,
                                      e.target.checked
                                    )
                                  }
                                />
                              </Form.Group>
                            ))}
                        </Form.Group>
                      ))}
                    <br />
                    <br />
                    {checkboxes &&
                      checkboxes.map((checkbox) => (
                        <Form.Group key={checkbox[0].id}>
                          <h6>{checkbox[0].name}</h6>
                          {checkbox[0] &&
                            checkbox[0].options &&
                            checkbox[0].options.map((option, index) => (
                              <Form.Group className='d-inline-flex' key={index}>
                                <Form.Check
                                  type='checkbox'
                                  label={option}
                                  name={checkbox[0].name}
                                  onChange={(e) =>
                                    manageSelectedChecks(
                                      checkbox[0].name,
                                      option,
                                      e.target.checked
                                    )
                                  }
                                />
                              </Form.Group>
                            ))}
                        </Form.Group>
                      ))}
                    <Form.Group>
                      <Form.Label>Enter Any Other Details</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={3}
                        onChange={(e) =>
                          setNewJob({ ...newJob, details: e.target.value })
                        }
                      />
                    </Form.Group>
                    <Button
                      variant='danger'
                      onClick={() => {
                        setShowDetailPage(false)
                        setSelectedChecks([])
                        setSelectedRadios([])
                        setNewJob({})
                      }}
                    >
                      Cancel
                    </Button>
                    <Button variant='primary' onClick={() => confirmQuote()}>
                      Next
                    </Button>
                  </Form>
                </Col>
              </Row>
            )}
          </Modal.Body>
        </Modal>
        <Modal
          show={showConfirmQuoteDetails}
          onHide={() => setShowConfirmQuoteDetails(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Title>Confirm Quote Details</Modal.Title>
          <Modal.Body>
            {selectedService && (
              <Row>
                <Col md={12}>
                  <Form>
                    <Form.Group>
                      <Image variant='top' src={selectedService.image} />
                      <Card.Title>{selectedService.mainService}</Card.Title>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>
                        Location Needed:{address && address.postcode}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>
                        Requested date: {startDate.toLocaleString()}
                      </Form.Label>
                    </Form.Group>
                    Options Selected:
                    <Form.Group>
                      {selectedChecks.map((check, index) => {
                        return (
                          <p key={index}>
                            {check.type} - {check.option}
                          </p>
                        )
                      })}

                      {selectedRadios.map((radio, index) => {
                        return (
                          <p key={index}>
                            {radio.type} - {radio.option}
                          </p>
                        )
                      })}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Other Details</Form.Label>
                      <Form.Label>{newJob.details}</Form.Label>
                    </Form.Group>
                    <Button variant='primary' onClick={() => backHandler()}>
                      Back
                    </Button>
                    <Button variant='primary' onClick={() => submitHandler()}>
                      Submit
                    </Button>
                  </Form>
                </Col>
              </Row>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default Section3
