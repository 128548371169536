import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Form } from 'react-bootstrap'
import { useUsers } from '../../contexts/UsersContext'
import { db } from '../../config/Firebase'
import { doc, updateDoc } from 'firebase/firestore'
import { useAdmin } from '../../contexts/AdminContext'

const BusinessDetails = () => {
  const [companyProfile, setCompanyProfile] = useState({})
  const { loggedOnUser } = useUsers()
  const [showForm, setShowForm] = useState(false)
  const [servicesOffered, setServicesOffered] = useState([])
  const { categories, citiesOffered } = useAdmin()
  const [cities, setCities] = useState([])

  useEffect(() => {
    if (loggedOnUser && loggedOnUser.company) {
      setCompanyProfile(loggedOnUser.company)
    }
    if (loggedOnUser && loggedOnUser.servicesOffered) {
      setServicesOffered(loggedOnUser.servicesOffered)
    }
    if (loggedOnUser && loggedOnUser.citiesOffered) {
      setCities(loggedOnUser.citiesOffered)
    }
  }, [loggedOnUser])

  const handleSubmit = async (e) => {
    e.preventDefault()
    await updateDoc(doc(db, 'users', loggedOnUser.id), {
      ...loggedOnUser,
      company: companyProfile,
      servicesOffered: servicesOffered,
      citiesOffered: cities,
    })
    setShowForm(false)
  }

  return (
    <Container>
      <Row>
        <h1>Business Details</h1>
      </Row>
      <br />
      <Row>
        <Col md={6}>
          Company Name: {companyProfile && companyProfile.name} <br />
          Company Email: {companyProfile && companyProfile.email} <br />
          Company Phone: {companyProfile && companyProfile.phone} <br />
          Company Address: {companyProfile && companyProfile.address} <br />
          Company City: {companyProfile && companyProfile.city} <br />
          Company Postcode: {companyProfile && companyProfile.postcode} <br />
          Company Registration Number:{' '}
          {companyProfile && companyProfile.registrationNumber} <br />
          Company VAT Number: {companyProfile && companyProfile.vatNumber}{' '}
          <br />
          Main Contact: {companyProfile && companyProfile.mainContact} <br />
        </Col>
        <Col md={6}>
          <h2> Services Offered: </h2>
          {servicesOffered &&
            servicesOffered.map((service) => <li>{service}</li>)}
          <br />
          <br />
          <h2> Cities Offered: </h2>
          {cities && cities.map((city) => <li>{city}</li>)}
        </Col>
      </Row>
      <Button
        type='submit'
        className='btn upgrade-btn'
        onClick={() => setShowForm(true)}
      >
        Update
      </Button>
      <br />
      <br />
      <br />
      {showForm && (
        <Row>
          <Col md={6}>
            <Form className='mb-3' onSubmit={handleSubmit}>
              <Form.Group className='mb-3'>
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type='text'
                  name='name'
                  defaultValue={companyProfile ? companyProfile.name : ''}
                  placeholder='Enter company name'
                  onChange={(e) =>
                    setCompanyProfile({
                      ...companyProfile,
                      name: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Company Email</Form.Label>
                <Form.Control
                  type='email'
                  name='email'
                  value={companyProfile ? companyProfile.email : ''}
                  placeholder='Enter company email'
                  onChange={(e) =>
                    setCompanyProfile({
                      ...companyProfile,
                      email: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Company Phone</Form.Label>
                <Form.Control
                  type='text'
                  name='phone'
                  defaultValue={companyProfile ? companyProfile.phone : ''}
                  placeholder='Enter company phone'
                  onChange={(e) =>
                    setCompanyProfile({
                      ...companyProfile,
                      phone: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Company Address</Form.Label>
                <Form.Control
                  type='text'
                  name='address'
                  defaultValue={companyProfile ? companyProfile.address : ''}
                  placeholder='Enter company address'
                  onChange={(e) =>
                    setCompanyProfile({
                      ...companyProfile,
                      address: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Company City</Form.Label>
                <Form.Control
                  type='text'
                  name='city'
                  defaultValue={companyProfile ? companyProfile.city : ''}
                  placeholder='Enter company city'
                  onChange={(e) =>
                    setCompanyProfile({
                      ...companyProfile,
                      city: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Company Postcode</Form.Label>
                <Form.Control
                  type='text'
                  name='postcode'
                  defaultValue={companyProfile ? companyProfile.postcode : ''}
                  placeholder='Enter company postcode'
                  onChange={(e) =>
                    setCompanyProfile({
                      ...companyProfile,
                      postcode: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Company Registration Number</Form.Label>
                <Form.Control
                  type='text'
                  name='registrationNumber'
                  defaultValue={
                    companyProfile ? companyProfile.registrationNumber : ''
                  }
                  placeholder='Enter company registration number'
                  onChange={(e) =>
                    setCompanyProfile({
                      ...companyProfile,
                      registrationNumber: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Company VAT Number</Form.Label>
                <Form.Control
                  type='text'
                  name='vatNumber'
                  defaultValue={companyProfile ? companyProfile.vatNumber : ''}
                  placeholder='Enter company VAT number'
                  onChange={(e) =>
                    setCompanyProfile({
                      ...companyProfile,
                      vatNumber: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Main Contact</Form.Label>
                <Form.Control
                  type='text'
                  name='mainContact'
                  defaultValue={
                    companyProfile ? companyProfile.mainContact : ''
                  }
                  placeholder='Enter main contact'
                  onChange={(e) =>
                    setCompanyProfile({
                      ...companyProfile,
                      mainContact: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Services Offered</Form.Label>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {categories &&
                    categories.map((category) => (
                      <Form.Check
                        type='switch'
                        key={category.id}
                        label={category.name}
                        checked={servicesOffered.includes(category.name)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setServicesOffered([
                              ...servicesOffered,
                              category.name,
                            ])
                          } else {
                            setServicesOffered(
                              servicesOffered.filter(
                                (service) => service !== category.name
                              )
                            )
                          }
                        }}
                      />
                    ))}
                </div>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Cities Offered</Form.Label>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {citiesOffered &&
                    citiesOffered.map((city1, index) => (
                      <Form.Check
                        type='switch'
                        key={index}
                        label={city1}
                        checked={cities.includes(city1)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCities([...cities, city1])
                          } else {
                            setCities(cities.filter((city) => city1 !== city))
                          }
                        }}
                      />
                    ))}
                </div>
              </Form.Group>

              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button type='submit' className='btn upgrade-btn'>
                  Update
                </Button>
                <Button
                  type='reset'
                  className='btn upgrade-btn'
                  onClick={() => setShowForm(false)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default BusinessDetails
