import React, { useState } from 'react'
import { Button, Form, Container, Row, Col } from 'react-bootstrap'
import { useUsers } from '../../contexts/UsersContext'
import { db } from '../../config/Firebase'
import { doc, updateDoc } from 'firebase/firestore'

const PersonalDetails = () => {
  const { loggedOnUser } = useUsers()

  const [businessUser, setBusinessUser] = useState({})
  const [showForm, setShowForm] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    await updateDoc(doc(db, 'users', loggedOnUser.id), businessUser)
    setShowForm(false)
    // navigate('/business')
  }

  return (
    <Container>
      <Row>
        <h1>Personal Details</h1>
      </Row>
      <br />
      <Row>
        <Col md={6}>
          Name: {loggedOnUser && loggedOnUser.name} <br />
          Email: {loggedOnUser && loggedOnUser.email} <br />
          Phone: {loggedOnUser && loggedOnUser.phone} <br />
          Address: {loggedOnUser && loggedOnUser.address} <br />
          City: {loggedOnUser && loggedOnUser.city} <br />
          Postcode: {loggedOnUser && loggedOnUser.postcode} <br />
        </Col>
      </Row>
      <Button
        type='submit'
        className='btn upgrade-btn'
        onClick={() => setShowForm(true)}
      >
        Update
      </Button>
      <br />
      <br />
      <br />

      {showForm && (
        <Row>
          <Col md={6}>
            <Form className='mb-3' onSubmit={handleSubmit}>
              <Form.Group className='mb-3'>
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type='text'
                  name='name'
                  defaultValue={loggedOnUser ? loggedOnUser.name : ''}
                  placeholder='Enter full name'
                  onChange={(e) =>
                    setBusinessUser({ ...businessUser, name: e.target.value })
                  }
                />
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type='email'
                  name='email'
                  value={loggedOnUser ? loggedOnUser.email : ''}
                  placeholder='Enter email'
                  disabled
                  onChange={(e) =>
                    setBusinessUser({ ...businessUser, email: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type='text'
                  name='phone'
                  defaultValue={loggedOnUser ? loggedOnUser.phone : ''}
                  placeholder='Enter phone number'
                  onChange={(e) =>
                    setBusinessUser({ ...businessUser, phone: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type='text'
                  name='address'
                  defaultValue={loggedOnUser ? loggedOnUser.address : ''}
                  placeholder='Enter address'
                  onChange={(e) =>
                    setBusinessUser({
                      ...businessUser,
                      address: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type='text'
                  name='city'
                  defaultValue={loggedOnUser ? loggedOnUser.city : ''}
                  placeholder='Enter city'
                  onChange={(e) =>
                    setBusinessUser({ ...businessUser, city: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Post Code</Form.Label>
                <Form.Control
                  type='text'
                  name='postcode'
                  defaultValue={loggedOnUser ? loggedOnUser.postcode : ''}
                  placeholder='Enter post code'
                  onChange={(e) =>
                    setBusinessUser({
                      ...businessUser,
                      postcode: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button variant='primary' type='submit'>
                  Submit
                </Button>
                <Button
                  variant='danger'
                  type='reset'
                  onClick={() => setShowForm(false)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default PersonalDetails
