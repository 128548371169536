import { Container } from 'react-bootstrap'

const PersonalRoleError = () => {
  return (
    <div
      style={{
        backgroundColor: '#ededff',
      }}
    >
      <Container>
        <h2>You need to login for personal Account. You are a Business user</h2>
      </Container>
    </div>
  )
}

export default PersonalRoleError
