import { Container, Button, Row, Col } from 'react-bootstrap'
import { useAdmin } from '../contexts/AdminContext'

const LiveJobs = () => {
  const { quotes } = useAdmin()

  return (
    <Container
      style={{ maxHeight: '200px', overflow: 'auto', marginTop: '20px' }}
    >
      <h1>Live Jobs</h1>
      {quotes &&
        quotes.length > 0 &&
        quotes.map((quote) => (
          <Row key={quote.id} style={{ padding: '3px' }}>
            <Col xs={10}>
              <p align='left'>
                {quote.created} : Job details: Customer needs{' '}
                {quote.serviceNeeded} {quote.categoryNeeded} at{' '}
                {quote.address
                  ? quote.address.postcode
                  : 'Location is not Specified by customer'}{' '}
                on {quote.requesteddate}
                {/* and special requirements by customer:{' '}
                  {quote.details ? quote.details : 'None'}. &nbsp;&nbsp;&nbsp; */}
              </p>
            </Col>
            <Col xs={1}>
              <Button
                onClick={() => {
                  alert(
                    'You need to register as a business before giving a quotation. \n'
                  )
                }}
              >
                Quote
              </Button>{' '}
            </Col>
          </Row>
        ))}
    </Container>
  )
}

export default LiveJobs
