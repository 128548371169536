import { useEffect, useState } from 'react'
import { Button, Form, Modal, Nav } from 'react-bootstrap'
import { useSignup } from '../../hooks/useSignup'
import { useNavigate } from 'react-router-dom'

export default function Register() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [role, setRole] = useState('personal')
  const { signup, isPending, error } = useSignup()

  const [showForm, setShowForm] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    setRole('personal')
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    signup(email, password, displayName, role)
  }

  return (
    <>
      <Modal show={showForm} centered onHide={() => setShowForm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Nav variant='pills' defaultActiveKey='link-1'>
            <Nav.Item>
              <Nav.Link
                eventKey='link-1'
                onClick={() => {
                  if (role === 'personal') {
                    setShowForm(false)
                  } else {
                    setRole('personal')
                    setShowForm(true)
                  }
                }}
              >
                Personal
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey='link-2'
                onClick={() => {
                  if (role === 'business') {
                    setShowForm(false)
                  } else {
                    setRole('business')
                    setShowForm(true)
                  }
                }}
              >
                Business
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Form.Group className='auth-form'>
            <div
              style={{ alignContent: 'center' }}
              className='site__brand site__brand__logo'
            >
              <img src='/images/assets/logo.png' alt='Omni Seva' />
            </div>

            <h2 style={{ textAlign: 'center' }}>
              {' '}
              Register as{' '}
              <span style={{ backgroundColor: 'yellow' }}>
                {role === 'personal' ? 'Personal' : 'Business'}{' '}
              </span>
              user
            </h2>
            <br />
            <Form.Label>Email</Form.Label>
            <Form.Control
              type='email'
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <Form.Label>Password</Form.Label>
            <Form.Control
              type='password'
              required
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <Form.Label>Your Name</Form.Label>
            <Form.Control
              type='text'
              required
              onChange={(e) => setDisplayName(e.target.value)}
              value={displayName}
            />
            {!isPending && (
              <Button
                className='btn'
                style={{ marginTop: 10 }}
                onClick={handleSubmit}
              >
                Register
              </Button>
            )}
            {isPending && (
              <Button className='btn' disabled>
                loading
              </Button>
            )}
            {error && <div className='error'>{error}</div>}
          </Form.Group>
        </Modal.Body>
      </Modal>
      {/* <Form.Group className='auth-form'>
     
        <h2 style={{alignSelf:"center"}}> Register</h2>
        <Form.Text
          style={{
            cursor: 'pointer',
            color: 'blue',
            textDecoration: 'underline',
            fontWeight: 'bold',
          }}
          onClick={() => {
            if(role === 'Personal')
                    {
                      setShowForm(false)
                    }
                    else
                    {                        
                      setRole('Personal')
                      setShowForm(true)
                    }
          }}
        >
          Register as a Personal user
        </Form.Text>
        <br />
        <br />
        <Form.Text
          style={{
            cursor: 'pointer',
            color: 'blue',
            textDecoration: 'underline',
            fontWeight: 'bold',
          }}
          onClick={() => {
            if(role === 'Business')
            {
              setShowForm(false)
            }
            else
            {                        
              setRole('Business')
              setShowForm(true)
            }
          }}
        >
          Register as a Business
        </Form.Text>
        <br />
        <br />
        Already registered?
        <br /> Please{' '}
        <Form.Text
          style={{
            cursor: 'pointer',
            color: 'blue',
            textDecoration: 'underline',
            fontWeight: 'bold',
          }}
          onClick={() => {
            navigate('/')
          }}
        >
          Login Here
        </Form.Text>
      </Form.Group>
      {showForm && (
        <Form.Group className='auth-form'>
           <div style={{alignContent:"center"}} className='site__brand site__brand__logo'>
              <img src='/images/assets/logo.png' alt='Omni Seva' />
          </div>

          <h2 style={{textAlign:"center"}}> Register as {role} user        
          </h2>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type='email'
            required
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            required
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <Form.Label>Your Name</Form.Label>
          <Form.Control
            type='text'
            required
            onChange={(e) => setDisplayName(e.target.value)}
            value={displayName}
          />
          {!isPending && (
            <Button className='btn' onClick={handleSubmit}>
              Register
            </Button>
          )}
          {isPending && (
            <Button className='btn' disabled>
              loading
            </Button>
          )}
          {error && <div className='error'>{error}</div>}
        </Form.Group>
      )} */}
    </>
  )

  // <form onSubmit={handleSubmit} className='auth-form'>
  //   <h2>login</h2>
  //   <label>
  //     <span>email:</span>
  //     <input
  //       required
  //       type='email'
  //       onChange={(e) => setEmail(e.target.value)}
  //       value={email}
  //     />
  //   </label>
  //   <label>
  //     <span>password:</span>
  //     <input
  //       required
  //       type='password'
  //       onChange={(e) => setPassword(e.target.value)}
  //       value={password}
  //     />
  //   </label>
  //   {!isPending && <button className='btn'>Log in</button>}
  //   {isPending && (
  //     <button className='btn' disabled>
  //       loading
  //     </button>
  //   )}
  //   {error && <div className='error'>{error}</div>}
  // </form>
}
