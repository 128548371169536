import { Col, Container, Row } from 'react-bootstrap'
import { useAdmin } from '../contexts/AdminContext'

const Section2 = () => {
  const { categories, setSelectedCategory } = useAdmin()
  const categoriesToShow = categories && categories.filter((cat) => cat.enabled)

  return (
    // Need to add Slider to this section
    <Container className='list-categories pb-5'>
      <div className='title-wrap align-center'>
        <h2>Home Services</h2>
      </div>
      <div className='inner'>
        <Row>
          {categoriesToShow &&
            categoriesToShow.map((categoryToShow) => (
              <Col key={categoryToShow.id} className='col-12 col-lg-4'>
                <div
                  className='item hover__box'
                  onClick={() => setSelectedCategory(categoryToShow.name)}
                >
                  <img src={categoryToShow.image} alt='' />
                  <span className='info'>
                    <span>{categoryToShow.name}</span>
                    <img src={'/images/uploaded/underline.png'} alt='' />
                  </span>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </Container>
  )
}

export default Section2
