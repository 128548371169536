import RadioChecks from '../../components/admin/RadioChecks'
import { useUsers } from '../../contexts/UsersContext'

const AdminDashboard = () => {
  const { loggedOnUser } = useUsers()

  return (
    <>
      {loggedOnUser && loggedOnUser.role !== 'admin' ? (
        <h1>You are not Authorised here. Get out</h1>
      ) : (
        'Admin Dashboard'
      )}
      <RadioChecks />
    </>
  )
}

export default AdminDashboard
