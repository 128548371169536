import { Container, Row, Col } from 'react-bootstrap'
import OrdersFeed from './OrdersFeed'
import MessagesFeed from './MessagesFeed'
import { useAdmin } from '../../contexts/AdminContext'
// import QuotesFeed from './QuotesFeed'

const Feeds = () => {
  const { clickedButton } = useAdmin()

  return (
    <Container>
      <Row className='member-statistical'>
        <Col sm={12}>
          {clickedButton === 'My Orders' && <OrdersFeed />}
          {clickedButton === 'Quotes Received' && <OrdersFeed />}
          {clickedButton === 'Messages' && <MessagesFeed />}
        </Col>
        <Col sm={4}></Col>

        <Col sm={4}>{/* <NotificationsFeed /> */}</Col>
      </Row>
    </Container>
  )
}

export default Feeds
