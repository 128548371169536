/**@global Admin Context */

/**
 * Get all Admin Data i.e. Users, Orders and any other collections - get all once and start SNAPSHOT listeners
 *
 *
 */
import React, { useContext, useEffect, useState } from 'react'
import { db } from '../config/Firebase'

import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { useUsers } from './UsersContext'
import { useAuthContext } from '../hooks/useAuthContext'
const AdminContext = React.createContext()

export const useAdmin = () => {
  return useContext(AdminContext)
}

const AdminProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState()
  const [services, setServices] = useState('')
  const [selectedCategory, setSelectedCategory] = useState()
  const [radioButtons, setRadioButtons] = useState([])
  const [checkBoxes, setCheckBoxes] = useState([])
  const [error, setError] = useState('')
  const [quotes, setQuotes] = useState([])
  const [myOrders, setMyOrders] = useState([])
  const [messages, setMessages] = useState([])
  const [newMessages, setNewMessages] = useState([])
  const [orders, setOrders] = useState([])
  const { loggedOnUser } = useUsers()
  const { user } = useAuthContext()
  const [search, setSearch] = useState([' '])
  const [selectedServ, setSelectedServ] = useState()
  const citiesOffered = ['London', 'Slough', 'Harrow', 'Birmingham']
  const [clickedLink, setClickedLink] = useState()
  const [clickedButton, setClickedButton] = useState()

  useEffect(() => {
    getServices()
    getCategories()
    getRadioButtons()
    getCheckBoxes()
    getQuotes()
    // getMyQuotes()
    getMessages()
    getOrders()
  }, [])

  useEffect(() => {
    getMyOrders()
  }, [loggedOnUser])
  // Admin Only
  const getCheckBoxes = async () => {
    const q = query(collection(db, 'checkBoxes'))
    await onSnapshot(
      q,
      (querySnapshot) => {
        let rcvdCheckBoxes = []
        querySnapshot.forEach((doc) => {
          rcvdCheckBoxes.push({ id: doc.id, ...doc.data() })
        })
        setCheckBoxes(rcvdCheckBoxes)
      },
      (err) => {
        console.log('ERROR SNAP: ', err)
        setError('Error getting Checkboxes')
      }
    )
    setLoading(false)
  }

  // Admin Only

  const getCategories = async () => {
    const q = query(collection(db, 'categories'), orderBy('name'))
    await onSnapshot(
      q,
      (querySnapshot) => {
        let rcvdCategories = []
        querySnapshot.forEach((doc) => {
          rcvdCategories.push({ id: doc.id, ...doc.data() })
        })
        setCategories(rcvdCategories)
        setSelectedCategory(null)
      },
      (err) => {
        console.log('ERROR SNAP: ', err)
        setError('Error getting Categories')
      }
    )
    setLoading(false)
  }
  // Admin Only

  const getServices = async () => {
    const q = query(collection(db, 'services'), orderBy('name'))
    await onSnapshot(
      q,
      (querySnapshot) => {
        let rcvdServices = []
        querySnapshot.forEach((doc) => {
          rcvdServices.push({ id: doc.id, ...doc.data() })
        })
        setServices(rcvdServices)
      },
      (err) => {
        console.log('ERROR SNAP: ', err)
        setError('Error getting Services')
      }
    )
    setLoading(false)
  }
  // Admin Only

  const getOrders = async () => {
    const q = query(
      collection(db, 'orders'),
      // where('status', '==', 'New'),
      orderBy('createdAt', 'desc')
    )
    await onSnapshot(
      q,
      (querySnapshot) => {
        let rcvdOrders = []
        querySnapshot.forEach((doc) => {
          rcvdOrders.push({ id: doc.id, ...doc.data() })
        })
        setOrders(rcvdOrders)
      },
      (err) => {
        console.log('ERROR SNAP: ', err)
        setError('Error getting Orders')
      }
    )
    setLoading(false)
  }

  const getRadioButtons = async () => {
    const q = query(collection(db, 'radioButtons'))
    await onSnapshot(
      q,
      (querySnapshot) => {
        let rcvdRadioButtons = []
        querySnapshot.forEach((doc) => {
          rcvdRadioButtons.push({ id: doc.id, ...doc.data() })
        })
        setRadioButtons(rcvdRadioButtons)
      },
      (err) => {
        console.log('ERROR SNAP: ', err)
        setError('Error getting Radios')
      }
    )
    setLoading(false)
  }

  // getQuotes for Admin Users

  const getQuotes = async () => {
    const q = query(collection(db, 'quotes'), orderBy('createdAt', 'desc'))
    await onSnapshot(
      q,
      (querySnapshot) => {
        let rcvdQuotes = []
        querySnapshot.forEach((doc) => {
          rcvdQuotes.push({ id: doc.id, ...doc.data() })
        })
        setQuotes(rcvdQuotes)
      },
      (err) => {
        console.log('ERROR SNAP: ', err)
        setError('Error getting Quotes')
      }
    )
    setLoading(false)
  }

  const getMyOrders = async () => {
    const by = user && user.email
    const q = query(collection(db, 'orders'), where('createdBy', '==', by))
    await onSnapshot(
      q,
      (querySnapshot) => {
        let rcvdOrders = []
        querySnapshot.forEach((doc) => {
          rcvdOrders.push({ id: doc.id, ...doc.data() })
        })
        // alert('rcvdQuotes', JSON.stringify(rcvdQuotes))
        setMyOrders(rcvdOrders)
      },
      (err) => {
        console.log('ERROR SNAP: ', err)
        setError('Error getting Orders')
      }
    )
    setLoading(false)
  }

  // Admin Only

  const getMessages = async () => {
    const q = query(collection(db, 'messages'), orderBy('createdAt', 'desc'))
    await onSnapshot(
      q,
      (querySnapshot) => {
        let rcvdMessages = []
        querySnapshot.forEach((doc) => {
          rcvdMessages.push({ id: doc.id, ...doc.data() })
        })
        setMessages(rcvdMessages)
        setNewMessages(rcvdMessages)
        // setNewMessages(rcvdMessages.filter((q) => q.status === 'new'))
      },
      (err) => {
        console.log('ERROR SNAP: ', err)
        setError('Error getting Quotes')
      }
    )
    setLoading(false)
  }

  const value = {
    categories,
    error,
    setError,
    services,
    radioButtons,
    checkBoxes,
    selectedCategory,
    setSelectedCategory,
    quotes,
    orders,
    myOrders,
    messages,
    newMessages,
    search,
    setSearch,
    selectedServ,
    setSelectedServ,
    citiesOffered,
    clickedLink,
    setClickedLink,
    clickedButton,
    setClickedButton,
  }
  return (
    <AdminContext.Provider value={value}>
      {!loading && children}
    </AdminContext.Provider>
  )
}

export default AdminProvider
