import { useState, useEffect } from 'react'
import { auth, db } from '../config/Firebase'
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from 'firebase/auth'
import { setDoc, doc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)

  const navigate = useNavigate()

  const signup = async (email, password, displayName, role) => {
    setError(null)
    setIsPending(true)

    try {
      // signup
      await createUserWithEmailAndPassword(auth, email, password)
        .then((res) => {
          sendEmailVerification(auth.currentUser)
          alert('We have sent an email. Please verify it')
          setDoc(doc(db, 'users', res.user.uid), {
            uid: res.user.uid,
            name: displayName,
            authProvider: 'local',
            email: res.user.email,
            role,
            createdOn: new Date().toLocaleString() + '',
          })
          updateProfile(res.user, { displayName })
          navigate('/login', { replace: true, state: { role, showForm: true } })
        })
        .catch((err) => {
          setError(err.message)
          setIsPending(false)
        })

      // upload user thumbnail
      // const uploadPath = `thumbnails/${res.user.uid}/${thumbnail.name}`
      // const img = await projectStorage.ref(uploadPath).put(thumbnail)
      // const imgUrl = await img.ref.getDownloadURL()

      // // add display AND PHOTO_URL name to user
      // await res.user.updateProfile({ displayName, photoURL: imgUrl })

      // create a user document
      // await db.collection('users').doc(res.user.uid).set({
      //   online: true,
      //   displayName,
      // })

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { signup, error, isPending }
}
