import { Card, Col, Container, Row, Form, Button } from 'react-bootstrap'
import { useAdmin } from '../../contexts/AdminContext'
import { useUsers } from '../../contexts/UsersContext'
import { useEffect, useState } from 'react'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../config/Firebase'

const MessagesFeed = () => {
  const { orders, messages } = useAdmin()
  const { loggedOnUser } = useUsers()
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [myMessages, setMyMessages] = useState([])
  const [showMsgForm, setShowMsgForm] = useState(false)
  const [moreInfo, setMoreInfo] = useState({})
  const [ordersToDisplay, setOrdersToDisplay] = useState([])
  const order = selectedOrder
  useEffect(() => {
    if (loggedOnUser) {
      if (loggedOnUser.role === 'business') {
        setMyMessages(
          messages &&
            messages.filter(
              (message) =>
                message.from === loggedOnUser.email ||
                message.to === loggedOnUser.email
            )
        )
        setOrdersToDisplay(
          orders &&
            orders.filter(
              (order) =>
                messages &&
                messages
                  .filter(
                    (message) =>
                      message.from === loggedOnUser.email ||
                      message.to === loggedOnUser.email
                  )
                  .map((message) => message.orderId)
                  .includes(order.id)
            )
        )
      }
    }
  }, [loggedOnUser, messages, orders])
  const handleMoreInfo = async () => {
    setShowMsgForm(false)

    await addDoc(
      collection(db, 'messages'),
      {
        from: loggedOnUser && loggedOnUser.email,
        orderId: order && order.id,
        message: moreInfo.message,
        to: order && order.createdBy,
        status: 'new',
        createdAt: new Date().toLocaleString() + '',
        type: 'moreInfo',
      },
      { merge: true }
    )
  }

  return (
    <Container>
      <Row className='member-statistical'>
        <Col sm={4}>
          <div className='item ob-item'>
            <div className='ob-head'>
              <h3> My Messages</h3>
              {/* <span
                onClick={() => alert('Navigate to All quotes Page')}
                style={{
                  color: '#1d1d55',
                  fontFamily: 'Jost',
                  cursor: 'pointer',
                }}
              >
                View all
              </span> */}
            </div>

            {ordersToDisplay &&
              ordersToDisplay.map((order) => (
                <div key={order.id}>
                  <Card border='primary'>
                    <Card.Body border='primary' align='left'>
                      <Row>
                        <Col sm={10}>
                          <p className='my-p'>
                            Order: {order.id} <br />
                            Date: {order.createdAt}
                            <br />
                            Order Status:{' '}
                            {/* <b
                              style={{
                                color:
                                  order.status === 'Completed'
                                    ? 'green'
                                    : order.status === 'Pending'
                                    ? 'red'
                                    : 'blue',
                              }}
                            >
                              {' '}
                              {order.status} <br />
                            </b> */}
                            {order.supplier &&
                            order.supplier !== loggedOnUser.email ? (
                              <b style={{ color: 'red' }}>Sorry, sold out</b>
                            ) : (
                              <b style={{ color: 'green' }}>{order.status}</b>
                            )}
                          </p>
                        </Col>
                        <Col sm={1}>
                          <br />
                          <br />
                          <i
                            onClick={() => setSelectedOrder(order)}
                            className='las la-angle-right'
                            style={{
                              fontSize: '24px',
                              color: '#1D1D55',
                              cursor: 'pointer',
                            }}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>{' '}
        </Col>
        <Col sm={8}>
          {selectedOrder && (
            <Card border='primary'>
              <Card.Body border='primary' align='left'>
                <Row>
                  <Col sm={10}>
                    <p className='my-p'>
                      Messages:
                      <br />
                      {myMessages &&
                        myMessages.length > 0 &&
                        myMessages.map((message, index) => (
                          <span key={index}>
                            {' '}
                            {message.orderId ===
                              (selectedOrder && selectedOrder.id) &&
                            message.from === loggedOnUser.email ? (
                              <b style={{ color: 'green' }}>
                                Me @ {message.createdAt}:{message.message}
                              </b>
                            ) : (
                              message.orderId ===
                                (selectedOrder && selectedOrder.id) &&
                              message.to === loggedOnUser.email && (
                                <b style={{ color: 'red' }}>
                                  Customer:@ {message.createdAt}:{' '}
                                  {message.message}
                                </b>
                              )
                            )}{' '}
                            <br />
                          </span>
                        ))}
                      <br />
                      {selectedOrder &&
                        !selectedOrder.supplier &&
                        selectedOrder.supplier !== loggedOnUser.email && (
                          <>
                            {' '}
                            Do you need more information?{' '}
                            <Button
                              variant='primary'
                              onClick={() => setShowMsgForm(true)}
                            >
                              Yes
                            </Button>
                            <br />
                          </>
                        )}
                      {showMsgForm && (
                        <div>
                          <h3>Send a message to customer</h3>
                          <Form.Control
                            as='textarea'
                            rows={3}
                            onChange={(e) =>
                              setMoreInfo({
                                ...moreInfo,
                                message: e.target.value,
                              })
                            }
                          />{' '}
                          <Button
                            variant='danger'
                            onClick={() => {
                              setShowMsgForm(false)
                              setMoreInfo({})
                            }}
                          >
                            Cancel
                          </Button>
                          <Button onClick={handleMoreInfo}>Send</Button>
                        </div>
                      )}
                      Job Details: <br />
                      Job ID: {selectedOrder.id} <br />
                      Created Date: {selectedOrder.createdAt}
                      <br />
                      Service Needed: {selectedOrder.serviceNeeded} of{' '}
                      {selectedOrder.categoryNeeded} category
                      <br /> Service Location:{' '}
                      {selectedOrder.address && selectedOrder.address.city}{' '}
                      {selectedOrder.address && selectedOrder.address.postcode}
                      <br />
                      Options Selected: <br />
                      {selectedOrder.radios &&
                        selectedOrder.radios.length > 0 &&
                        selectedOrder.radios.map((radio, index) => (
                          <span key={index}>
                            {' '}
                            {radio.type}: {radio.option}
                            <br />
                          </span>
                        ))}
                      <br /> Items Selected: <br />
                      {selectedOrder.checks &&
                        selectedOrder.checks.length > 0 &&
                        selectedOrder.checks.map((check, index) => (
                          <span key={index}>
                            {' '}
                            {check.type}: {check.option}
                            <br />
                          </span>
                        ))}
                      <br />
                      Order Status:{' '}
                      {selectedOrder.supplier &&
                      selectedOrder.supplier !== loggedOnUser.email ? (
                        <b style={{ color: 'red' }}>Sorry, sold out</b>
                      ) : (
                        <b style={{ color: 'green' }}>{selectedOrder.status}</b>
                      )}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
          <br />
          <br />
        </Col>
      </Row>
    </Container>
  )
}

export default MessagesFeed
