import { useState } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../config/Firebase'
import { useUsers } from '../../contexts/UsersContext'

const OrderDetails = (props) => {
  const { loggedOnUser } = useUsers()
  // const order = location.state && location.state.order
  const order = props.order
  const [showMsgForm, setShowMsgForm] = useState(false)
  const [moreInfo, setMoreInfo] = useState({})
  const [quoteSent, setQuoteSent] = useState({})
  const [showMsg1Form, setShowMsg1Form] = useState(false)

  const handleMoreInfo = async () => {
    setShowMsgForm(false)

    await addDoc(
      collection(db, 'messages'),
      {
        from: loggedOnUser && loggedOnUser.email,
        orderId: order && order.id,
        message: moreInfo.message,
        to: order && order.createdBy,
        status: 'new',
        createdAt: new Date().toLocaleString() + '',
        type: 'moreInfo',
      },
      { merge: true }
    )
  }

  const handleSendQuote = async () => {
    setShowMsg1Form(false)

    await addDoc(
      collection(db, 'quotes'),
      {
        from: loggedOnUser && loggedOnUser.email,
        orderId: order && order.id,
        message: quoteSent.message,
        to: order && order.createdBy,
        status: 'new',
        createdAt: new Date().toLocaleString() + '',
        type: 'quote',
      },
      { merge: true }
    )
  }

  return (
    <Container>
      <Row className='member-statistical'>
        <Col sm={12}>
          Need Quote for order no: {order && order.id}: <br />
          {order && (
            <>
              {' '}
              Job posted at {order.createdAt}. The details are:
              <br /> We need {order.serviceNeeded} of {order.categoryNeeded}{' '}
              category at{' '}
              {order.address && order.address.city
                ? order.address.city
                : ' Location is not Specified by customer'}
              <br />
              Special requirements: {order.details ? order.details : 'None'}.
              <br /> service details: <br />
              Service needed on: {order.requesteddate}
              {order.checks &&
                order.checks.map((check, index) => (
                  <div key={index}>
                    {check.type}: {check.option}
                    <br />
                  </div>
                ))}
              {order.radios &&
                order.radios.map((radio, index) => (
                  <div key={index}>
                    {' '}
                    {radio.type}: {radio.option}
                    <br />
                  </div>
                ))}
            </>
          )}
          <Button
            onClick={() => {
              setShowMsgForm(false)
              setShowMsg1Form(true)
            }}
          >
            Quote
          </Button>
          <Button
            onClick={() => {
              setShowMsgForm(true)
              setShowMsg1Form(false)
            }}
          >
            More Info
          </Button>
          {/* <Button onClick={handleNotInterested}>Not interested</Button> */}
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          {showMsgForm && (
            <div>
              <h3>Send a message to customer</h3>
              <Form.Control
                as='textarea'
                rows={3}
                onChange={(e) =>
                  setMoreInfo({ ...moreInfo, message: e.target.value })
                }
              />{' '}
              <Button
                variant='danger'
                onClick={() => {
                  setShowMsgForm(false)
                  setMoreInfo({})
                }}
              >
                Cancel
              </Button>
              <Button onClick={handleMoreInfo}>Send</Button>
            </div>
          )}
          {showMsg1Form && (
            <div>
              <h3>Send a quote to customer</h3>
              <Row>
                <Col sm={2}>
                  <Form.Label>My Quote is: £</Form.Label>
                </Col>
                <Col sm={2}>
                  <Form.Control
                    type='number'
                    min={0}
                    onChange={(e) =>
                      setQuoteSent({ ...quoteSent, message: e.target.value })
                    }
                  />{' '}
                </Col>
              </Row>

              <Button
                variant='danger'
                onClick={() => {
                  setShowMsg1Form(false)
                  setQuoteSent({})
                }}
              >
                Cancel
              </Button>
              <Button onClick={handleSendQuote}>Send</Button>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default OrderDetails
