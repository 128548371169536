import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Link, useNavigate } from 'react-router-dom'
import { useLogout } from '../../hooks/useLogout'
import { Col, Dropdown } from 'react-bootstrap'
import { useAuthContext } from '../../hooks/useAuthContext'

const SuppProfileHeader = () => {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const { logout } = useLogout()
  const location = useLocation()
  const [showForm, setShowForm] = useState(
    (location && location.state && location.state.showForm) || false
  )
  const [role, setRole] = useState(
    (location && location.state && location.state.role) || 'none'
  )
  return (
    <>
      {/* <Navbar className='my-nav' variant='light' expand='lg'> */}
      <Navbar className='my-nav' expand='lg'>
        <Container>
          <Navbar.Toggle aria-controls='supp-header' />
          <Navbar.Collapse id='supp-header'>
            <Nav
              defaultActiveKey={'SupplierProfile'}
              //   onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
            >
              <Nav.Link
                onClick={() => navigate('/business')}
                // eventKey={'SupplierProfile'}
                className='my-link'
              >
                Dashboard
              </Nav.Link>
              {/* <Nav.Link className='my-link' eventKey='MyOrders'>
                My orders
              </Nav.Link>
              <Nav.Link className='my-link' eventKey='MyServices'>
                My services
              </Nav.Link>{' '} */}
              <Nav.Link
                className='my-link'
                as={Link}
                to='/business/companydetails'
              >
                Business Details
              </Nav.Link>{' '}
              <Nav.Link
                className='my-link'
                as={Link}
                to='/business/personaldetails'
              >
                personal settings
              </Nav.Link>{' '}
              <Nav.Link
                className='my-link'
                style={{ paddingRight: '30px' }}
                as={Link}
                to='/'
              >
                Home
              </Nav.Link>{' '}
              {!user ? (
                <Col sm={2}>
                  <Dropdown>
                    <Dropdown.Toggle variant='success' id='dropdown-basic'>
                      Login
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        background: 'red',
                        fontweight: 'bold',
                        cursor: 'pointer',
                      }}
                    >
                      <Dropdown.Item className='logindropdownitem'>
                        <button
                          style={{
                            background: 'transparent',
                            color: 'black',
                            border: 'none',
                          }}
                          onClick={() => {
                            navigate('/login', {
                              replace: true,
                              state: { role: 'Personal', showForm: true },
                            })
                          }}
                        >
                          Personal Login{' '}
                        </button>
                      </Dropdown.Item>
                      <Dropdown.Item className='logindropdownitem'>
                        <button
                          style={{
                            background: 'transparent',
                            color: 'black',
                            border: 'none',
                          }}
                          onClick={() => {
                            navigate('/login', {
                              replace: true,
                              state: { role: 'Business', showForm: true },
                            })
                          }}
                        >
                          {' '}
                          Business Login{' '}
                        </button>
                      </Dropdown.Item>
                      <Dropdown.Item className='logindropdownitem'>
                        <button
                          style={{
                            background: 'transparent',
                            color: 'black',
                            border: 'none',
                          }}
                          onClick={() => {
                            setRole('none')
                            setShowForm(false)
                            navigate('/register')
                          }}
                        >
                          Register Here
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              ) : (
                <Col
                  sm={2}
                  className='right-header__login right-header__button btn'
                  style={{ paddingLeft: '0px' }}
                >
                  <Link className='open-login' onClick={() => logout()}>
                    Logout
                  </Link>
                </Col>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  )
}

export default SuppProfileHeader
