import { Card, Col, Container, Form, Row } from 'react-bootstrap'
import { useAdmin } from '../../contexts/AdminContext'
import { useUsers } from '../../contexts/UsersContext'
import { useEffect, useState } from 'react'
import OrderDetails from './OrderDetails'

const OrdersFeed = () => {
  const { quotes, myOrders } = useAdmin()
  const { loggedOnUser } = useUsers()

  const [filteredOrders, setFilteredOrders] = useState([])
  const [selectedOrder, setSelectedOrder] = useState(null)

  useEffect(() => {
    if (loggedOnUser) {
      if (loggedOnUser.role === 'personal') {
        setFilteredOrders(myOrders)
      }
    }
  }, [loggedOnUser, myOrders])

  return (
    <Container>
      <Row className='member-statistical'>
        <Col sm={4}>
          <div className='item ob-item'>
            <div className='ob-head'>
              <h3>My Orders</h3>
              {/* <span
          onClick={() => alert('Navigate to All quotes Page')}
          style={{
            color: '#1d1d55',
            fontFamily: 'Jost',
            cursor: 'pointer',
          }}
        >
          View all
        </span> */}
            </div>

            {filteredOrders &&
              filteredOrders.map((order) => (
                <div key={order.id}>
                  <Card border='primary'>
                    <Card.Body border='primary' align='left'>
                      <Row>
                        <Col sm={10}>
                          <p className='my-p1'>
                            Date Created: {order && order.createdAt}
                            <br />
                            Description: {order.serviceNeeded}
                            <br />
                            Category: {order.categoryNeeded}
                            <br />
                            Location: {order.address.city}
                            <br />
                            Status:{' '}
                            <b
                              style={{
                                color:
                                  order.status === 'Completed'
                                    ? 'green'
                                    : order.status === 'Pending'
                                    ? 'red'
                                    : 'blue',
                              }}
                            >
                              {' '}
                              {order.status}
                            </b>
                          </p>
                        </Col>
                        <Col sm={1}>
                          <br />
                          <br />
                          <i
                            onClick={() =>
                              // navigate(`/business/orders/${order.id}`, {
                              //   state: { order },
                              // })
                              setSelectedOrder(order)
                            }
                            className='las la-angle-right'
                            style={{
                              fontSize: '24px',
                              color: '#1D1D55',
                              cursor: 'pointer',
                            }}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
        </Col>
        {selectedOrder && (
          <Col sm={8}>
            <Card border='primary'>
              <Card.Body border='primary' align='left'>
                <Row>
                  <Col sm={10}>
                    <OrderDetails order={selectedOrder} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  )
}

export default OrdersFeed
