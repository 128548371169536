import { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap'
import { addDoc, collection, doc, setDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../config/Firebase'
import { useUsers } from '../../contexts/UsersContext'
import { useAdmin } from '../../contexts/AdminContext'

const OrderDetails = (props) => {
  const { loggedOnUser } = useUsers()
  const { quotes, messages } = useAdmin()
  const [myMessages, setMyMessages] = useState([])
  const [myQuotes, setMyQuotes] = useState([])
  const [showMsgModal, setShowMsgModal] = useState()
  const [message, setMessage] = useState('')
  const [supplier, setSupplier] = useState('  ')

  const selectedOrder = props.order
  useEffect(() => {
    if (loggedOnUser) {
      if (loggedOnUser.role === 'personal') {
        setMyMessages(
          messages &&
            messages.filter(
              (message) =>
                (message.to === loggedOnUser.email ||
                  message.from === loggedOnUser.email) &&
                message.orderId === selectedOrder.id
            )
        )
        setMyQuotes(
          quotes &&
            quotes.filter(
              (quote) =>
                quote.to === loggedOnUser.email &&
                quote.orderId === selectedOrder.id
            )
        )
      }
    }
  }, [loggedOnUser, messages, quotes, selectedOrder.id])

  const updateQuote = async (quote, status) => {
    status === 'Quote Accepted' &&
      (await updateDoc(
        doc(db, 'orders', selectedOrder.id),
        {
          status,
          supplier: quote.from,
        },
        { merge: true }
      ))

    await updateDoc(
      doc(db, 'quotes', quote.id),
      { supplier: quote.from, status },
      { merge: true }
    )
  }

  const sendMyOffer = async (quote, offerValue) => {
    await updateDoc(
      doc(db, 'quotes', quote.id),
      {
        customerOffer: offerValue,
      },
      { merge: true }
    )
  }

  const sendMessage = async (e) => {
    e.preventDefault()
    try {
      await addDoc(collection(db, 'messages'), {
        from: loggedOnUser.email,
        to: supplier,
        message,
        orderId: selectedOrder.id,
        createdAt: new Date().toLocaleString() + '',
      })
      setMessage('')
      setShowMsgModal(false)
    } catch (error) {
      alert(error)
    }
  }

  return (
    <>
      <Container>
        <Row className='member-statistical'>
          <Col sm={12}>
            Job Details: <br />
            Job ID: {selectedOrder.id} <br />
            Created Date: {selectedOrder.createdAt}
            <br />
            Service Needed: {selectedOrder.serviceNeeded} of{' '}
            {selectedOrder.categoryNeeded} category
            <br />
            Options Selected: <br />
            {selectedOrder.radios &&
              selectedOrder.radios.length > 0 &&
              selectedOrder.radios.map((radio, index) => (
                <span key={index}>
                  {' '}
                  {radio.type}: {radio.option}
                  <br />
                </span>
              ))}
            <br /> Items Selected: <br />
            {selectedOrder.checks &&
              selectedOrder.checks.length > 0 &&
              selectedOrder.checks.map((check, index) => (
                <span key={index}>
                  {' '}
                  {check.type}: {check.option}
                  <br />
                </span>
              ))}
            <br />
            Location Details:{' '}
            {selectedOrder.address && selectedOrder.address.city
              ? selectedOrder.address.city
              : ' Location is not Specified by customer'}{' '}
            {selectedOrder.address && selectedOrder.address.postcode}
            <br />
            Status:{' '}
            <b
              style={{
                color:
                  selectedOrder.status === 'Completed'
                    ? 'green'
                    : selectedOrder.status === 'Pending'
                    ? 'red'
                    : 'blue',
              }}
            >
              {' '}
              {selectedOrder.status}
            </b>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className='member-statistical'>
          {myQuotes && myQuotes.length > 0 ? (
            <Col sm={12}>
              <h3>Quotes Received for this order: {myQuotes.length}</h3>
              {myQuotes.map((quote, index) => (
                <div key={index}>
                  <p>
                    <b>Quote ID: </b> {quote.id}
                  </p>
                  <p>
                    <b>Quote Date: </b> {quote.createdAt}
                  </p>
                  <p>
                    <b>Quote From: </b> {quote.from}
                  </p>
                  <p>
                    <b>Quote Amount: </b> £{quote.message}
                  </p>
                  {quote.customerOffer && (
                    <p>
                      <b>Your Offer: </b> £{quote.customerOffer}
                    </p>
                  )}
                  <p>
                    <b>Quote Status: </b>{' '}
                    <strong style={{ color: 'green' }}>
                      {quote.status === 'Quote Accepted' && quote.status}{' '}
                    </strong>
                    <strong style={{ color: 'blue' }}>
                      {quote.status !== 'Quote Accepted' && quote.status}{' '}
                    </strong>
                  </p>
                  {quote.status !== 'Quote Accepted' &&
                    quote.status !== 'Quote Rejected' && (
                      <>
                        <Button
                          variant='primary'
                          onClick={() => updateQuote(quote, 'Quote Accepted')}
                        >
                          Accept{' '}
                        </Button>
                        <Button
                          variant='danger'
                          onClick={() => updateQuote(quote, 'Quote Rejected')}
                        >
                          Reject{' '}
                        </Button>
                        <Button
                          variant='warning'
                          onClick={() => {
                            let offerValue = parseFloat(
                              prompt('Enter your offer')
                            )

                            offerValue = !offerValue
                              ? parseFloat(prompt('Please enter numbers only'))
                              : offerValue

                            sendMyOffer(quote, offerValue)
                          }}
                        >
                          Your Offer
                        </Button>
                      </>
                    )}

                  <br />
                </div>
              ))}
            </Col>
          ) : (
            <Col sm={12}>
              <h3>Quotes Received for this order: 0</h3>
            </Col>
          )}
        </Row>
      </Container>

      <Container>
        <Row className='member-statistical'>
          {myMessages && myMessages.length > 0 ? (
            <Col sm={12}>
              <h3>Messages in this order {myMessages.length}</h3>
              {myMessages.map((message, index) => (
                <div key={index}>
                  <p>
                    <b>Message ID: </b> {message.id}
                  </p>
                  <p>
                    <b>Message Date: </b> {message.createdAt}
                  </p>
                  <p>
                    <b>Message From: </b>{' '}
                    <b style={{ color: 'green' }}>
                      {message.from === loggedOnUser.email && 'Me'}
                    </b>
                    <b style={{ color: 'red' }}>
                      {message.from !== loggedOnUser.email && message.from}
                    </b>
                  </p>
                  <p>
                    <b>Message: </b> {message.message}
                  </p>
                  <Button
                    variant='primary'
                    onClick={() => {
                      setSupplier(message.from)
                      setShowMsgModal(true)
                    }}
                  >
                    Reply
                  </Button>
                  <br />
                </div>
              ))}
            </Col>
          ) : (
            <Col sm={12}>
              <h3>Messages in this order: 0</h3>
            </Col>
          )}
        </Row>
      </Container>
      <Modal show={showMsgModal} onHide={() => setShowMsgModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Send Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId='exampleForm.ControlTextarea1'>
              <Form.Label>Message</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowMsgModal(false)}>
            Close
          </Button>
          <Button variant='primary' onClick={(e) => sendMessage(e)}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default OrderDetails
