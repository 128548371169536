import { useState, useEffect } from 'react'
import { auth, db } from '../config/Firebase'
import { useAuthContext } from './useAuthContext'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { useUsers } from '../contexts/UsersContext'

export const useLogin = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()
  const navigate = useNavigate()
  const { updateLoginTime } = useUsers()
  const login = async (email, password, role) => {
    setError(null)
    setIsPending(true)
    try {
      await signInWithEmailAndPassword(auth, email, password)
        .then((res) => {
          dispatch({ type: 'LOGIN', payload: res.user })
          role === 'personal' && navigate('/')
          role === 'business' && navigate('/business')
          role === 'admin' && navigate('/admin')
          console.log('Meta data:', res.user.metadata)
          updateLoginTime(res.user.uid)
          setIsPending(false)
        })
        .catch((err) => {
          setError(err.message)
          setIsPending(false)
        })

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { login, isPending, error }
}
