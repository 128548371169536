import { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useLogin } from '../../hooks/useLogin'
import { useLocation } from 'react-router-dom'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, error, isPending } = useLogin()
  const location = useLocation()
  const [showForm, setShowForm] = useState(
    (location && location.state && location.state.showForm) || true
  )
  const [role, setRole] = useState(
    (location && location.state && location.state.role) || 'none'
  )

  useEffect(() => {
    if (location && location.state && location.state.showForm) {
      setShowForm(location.state.showForm)
    }
    if (location && location.state && location.state.role) {
      setRole(location.state.role)
    }
    if (location && location.pathname === '/person') {
      setRole('personal')
    } else if (location && location.pathname === '/business') {
      setRole('business')
    }
  }, [location])

  const handleSubmit = (e) => {
    e.preventDefault()
    login(email, password, role)
  }

  return (
    <>
      <Modal show={showForm} centered onHide={() => setShowForm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='auth-form'>
            <div
              style={{ alignContent: 'center' }}
              className='site__brand site__brand__logo'
            >
              <img src='/images/assets/logo.png' alt='Omni Seva' />
            </div>
            <h2 style={{ textAlign: 'center' }}>{role} Log in</h2>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type='email'
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <Form.Label>Password</Form.Label>
            <Form.Control
              type='password'
              required
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <Form.Text
              className='text-muted'
              style={{
                cursor: 'pointer',
                color: 'blue',
                textDecoration: 'underline',
                fontWeight: 'bold',
              }}
              onClick={() => alert('Handle Forgot Password')}
            >
              {' '}
              Forgot password?
            </Form.Text>
            <br />
            <br />
            {!isPending && (
              <Button className='btn' onClick={handleSubmit}>
                Log in
              </Button>
            )}
            {isPending && (
              <Button className='btn' disabled>
                loading
              </Button>
            )}
            {error && <div className='error'>{error}</div>}
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
    //   <Form.Group className='auth-form'>
    //     <Form.Text
    //       style={{
    //         cursor: 'pointer',
    //         color: 'blue',
    //         textDecoration: 'underline',
    //         fontWeight: 'bold',
    //       }}
    //       onClick={() => {
    //         if (role === 'Personal') {
    //           setShowForm(false)
    //         } else {
    //           setRole('Personal')
    //           setShowForm(true)
    //         }
    //       }}
    //     >
    //       Personal Login
    //     </Form.Text>
    //     <br />
    //     <br />
    //     <Form.Text
    //       style={{
    //         cursor: 'pointer',
    //         color: 'blue',
    //         textDecoration: 'underline',
    //         fontWeight: 'bold',
    //       }}
    //       onClick={() => {
    //         if (role === 'Business') {
    //           setShowForm(false)
    //         } else {
    //           setRole('Business')
    //           setShowForm(true)
    //         }
    //       }}
    //     >
    //       Business Login
    //     </Form.Text>
    //     <br />
    //     <br />
    //     No account with us yet?
    //     <br /> Please{' '}
    //     <Form.Text
    //       style={{
    //         cursor: 'pointer',
    //         color: 'blue',
    //         textDecoration: 'underline',
    //         fontWeight: 'bold',
    //       }}
    //       onClick={() => {
    //         navigate('/register')
    //       }}
    //     >
    //       Register Here
    //     </Form.Text>
    //   </Form.Group>
    // )}
    // {showForm && (
    //   <Form.Group className='auth-form'>
    //     <div
    //       style={{ alignContent: 'center' }}
    //       className='site__brand site__brand__logo'
    //     >
    //       <img src='/images/assets/logo.png' alt='Omni Seva' />
    //     </div>
    //     <h2 style={{ textAlign: 'center' }}>{role} Log in</h2>
    //     <Form.Label>Email</Form.Label>
    //     <Form.Control
    //       type='email'
    //       required
    //       onChange={(e) => setEmail(e.target.value)}
    //       value={email}
    //     />
    //     <Form.Label>Password</Form.Label>
    //     <Form.Control
    //       type='password'
    //       required
    //       onChange={(e) => setPassword(e.target.value)}
    //       value={password}
    //     />
    //     <Form.Text
    //       className='text-muted'
    //       style={{
    //         cursor: 'pointer',
    //         color: 'blue',
    //         textDecoration: 'underline',
    //         fontWeight: 'bold',
    //       }}
    //       onClick={() => alert('Handle Forgot Password')}
    //     >
    //       {' '}
    //       Forgot password?
    //     </Form.Text>
    //     <br />
    //     <br />
    //     {!isPending && (
    //       <Button className='btn' onClick={handleSubmit}>
    //         Log in
    //       </Button>
    //     )}
    //     {isPending && (
    //       <Button className='btn' disabled>
    //         loading
    //       </Button>
    //     )}
    //     {error && <div className='error'>{error}</div>}
    //   </Form.Group>
  )
}
